import { memo, useCallback } from 'react';
import { Button, Flex, Select } from 'antd';
import { CharacteristicItemType, Option } from '@/types/types';
import CrossIcon from '/public/icons/cross.svg';
import Vicon from '/public/icons/v.svg';
import styles from './CharacteristicsFilterItem.module.scss';
import { filterOption } from '@/utils/filterOptions';
import { LoadingOutlined } from '@ant-design/icons';
import cx from 'classnames';

type CharacteristicsFilterItemProps = {
  names: Option[];
  values: Record<string, Option[]>;
  item: CharacteristicItemType;
  isOptionsLoading: boolean;
  onChangeItem: (value: CharacteristicItemType) => void;
  onDeleteItem: (item: CharacteristicItemType) => void;

  selectClassName?: string;
};

export const CharacteristicsFilterItem = memo(
  ({
    names,
    values,
    item,
    isOptionsLoading,
    selectClassName,
    onChangeItem,
    onDeleteItem,
  }: CharacteristicsFilterItemProps) => {
    const valueOptions: Option[] = values[item.name?.value ?? ''] ?? [];

    const handleChangeName = useCallback(
      (_: unknown, option: Option) => {
        onChangeItem({
          id: item.id,
          name: option,
          value: undefined,
        });
      },
      [onChangeItem, item],
    );

    const handleChangeValue = useCallback(
      (_: unknown, option: Option) => {
        onChangeItem({
          id: item.id,
          name: item.name,
          value: option,
        });
      },
      [onChangeItem, item],
    );

    const handleDelete = useCallback(() => {
      onDeleteItem(item);
    }, [onDeleteItem, item]);

    return (
      <Flex
        gap={16}
        align="center"
      >
        <Select
          options={names}
          value={item.name}
          className={cx(styles.select, selectClassName)}
          placeholder="Выберите характеристику"
          showSearch
          filterOption={filterOption}
          suffixIcon={isOptionsLoading ? <LoadingOutlined spin /> : <Vicon className="v-icon" />}
          onSelect={handleChangeName}
        />
        <Select
          options={valueOptions}
          value={item.value}
          className={cx(styles.select, selectClassName)}
          showSearch
          placeholder="Выберите значение"
          suffixIcon={isOptionsLoading ? <LoadingOutlined spin /> : <Vicon className="v-icon" />}
          onSelect={handleChangeValue}
        />
        <Button
          icon={<CrossIcon />}
          type="link"
          className={styles.crossButton}
          onClick={handleDelete}
        />
      </Flex>
    );
  },
);

CharacteristicsFilterItem.displayName = 'CharacteristicsFilterItem';
