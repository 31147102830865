import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { postData } from '@/api/commonApi/commonApi';

export const postNewNmckMedDevice = async (body: {
  copyFromId: number | null;
  okpdCode?: string;
  ktruCode?: string;
  positionName?: string;
  nkmi?: number;
}): Promise<NmckByIdResponse> => {
  return postData('/nmck/med-devices', body);
};
