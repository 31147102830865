import { memo, useCallback, useState } from 'react';
import { Button, Flex, Tooltip } from 'antd';
import styles from './NmckGridPropositionColumnTitle.module.scss';
import CrossIcon from 'public/icons/cross.svg';
import PlusIcon from 'public/icons/plus.svg';
import { Proposition } from '@/api/nmckApi/nmckApiTypes';
import cx from 'classnames';

type Props = {
  propositions: Proposition[];
  columnIndex: number;
  onDeleteProposition: (columnNumber: number) => Promise<unknown>;
  onAddProposition: () => Promise<unknown>;
};

export const NmckGridPropositionColumnTitle = memo(
  ({ propositions, columnIndex, onDeleteProposition, onAddProposition }: Props) => {
    const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);
    const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);
    const proposition = propositions[columnIndex];
    const isDeleteButtonShown = propositions.length > 1;
    const isAddButtonShown = propositions.length - 1 === columnIndex;

    const handleDeleteButtonClick = useCallback(async () => {
      setIsDeleteButtonLoading(true);
      try {
        await onDeleteProposition(proposition.columnNumber);
        setIsDeleteButtonLoading(false);
      } catch (err: unknown) {
        setIsDeleteButtonLoading(false);
      }
    }, [onDeleteProposition, proposition.columnNumber]);

    const handleAddButtonClick = useCallback(async () => {
      setIsAddButtonLoading(true);
      try {
        await onAddProposition();
        setIsAddButtonLoading(false);
      } catch (err: unknown) {
        setIsAddButtonLoading(false);
      }
    }, [onAddProposition]);

    return (
      <Flex
        justify="space-between"
        align="center"
      >
        <Flex
          align="center"
          gap={6}
        >
          Цена {proposition.columnNumber}
          {isDeleteButtonShown && (
            <Tooltip title="Удалить цену">
              <Button
                className={cx(styles.button, styles.buttonRemove)}
                loading={isDeleteButtonLoading}
                size="small"
                type="link"
                icon={<CrossIcon />}
                onClick={handleDeleteButtonClick}
              />
            </Tooltip>
          )}
        </Flex>
        {isAddButtonShown && (
          <Tooltip title="Добавить цену">
            <Button
              className={styles.button}
              loading={isAddButtonLoading}
              size="small"
              type="link"
              icon={<PlusIcon />}
              onClick={handleAddButtonClick}
            />
          </Tooltip>
        )}
      </Flex>
    );
  },
);

NmckGridPropositionColumnTitle.displayName = 'NmckGridPropositionColumnTitle';
