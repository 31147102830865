'use client';

import { memo, useCallback, useEffect, useState } from 'react';
import { Header, InitialPage, KtruList, OkpdList } from '@/widgets/OkpdKtru/components';
import styles from './OkpdKtruPage.module.scss';
import { CharacteristicsFilter } from '@/widgets';
import { CharacteristicsParam } from '@/types/types';
import { Flex } from 'antd';
import {
  OkpdKtruModalPageParams,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { useSearchParams } from 'next/navigation';
import { useGetKtruCharacteristics } from '@/api/okpdApi/okpdApi';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { useRedirectOnSingleOkpdKtruResult } from '@/widgets/OkpdKtru/hooks/useRedirectOnSingleOkpdKtruResult';
import { setPageTitle } from '@/utils/setPageTitle';
import { generatePageMetaData } from '@/utils/generatePageMetaData';
import { pageRoutes } from '@/app/pageRoutes';

const SEARCH_QUERY_PARAM = 'search';

type Props = {
  modalPageParams?: OkpdKtruModalPageParams;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdKtruPage = memo(({ modalPageParams, setModalPageParams }: Props) => {
  const searchParams = useSearchParams();
  const [search, setSearch] = useState<string>(
    (setModalPageParams ? modalPageParams?.searchString : searchParams.get(SEARCH_QUERY_PARAM)) ??
      '',
  );
  const [characteristicsParams, setCharacteristicsParams] = useState<CharacteristicsParam[]>([]);

  useRedirectOnSingleOkpdKtruResult({
    searchString: search,
    characteristics: characteristicsParams,
    setModalPageParams,
  });

  const { data: characteristicsOptions = [], isLoading: isCharacteristicsOptionsLoading } =
    useGetKtruCharacteristics({
      searchString: search,
    });

  const onCharacteristicsChange = useCallback(
    (newChars: CharacteristicsParam[]) => setCharacteristicsParams(newChars),
    [],
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      if (!setModalPageParams) {
        const url = new URL(window.location.href);
        url.searchParams.set(SEARCH_QUERY_PARAM, value);
        window.history.replaceState({}, '', url.toString());
        setPageTitle(
          generatePageMetaData({ pageRoute: pageRoutes.okpd2, additionalTitle: value }).title,
        );
      }

      setSearch(value);
    },
    [setModalPageParams],
  );

  useEffect(() => {
    if (setModalPageParams && modalPageParams) {
      setSearch(modalPageParams.searchString);
    }
  }, [modalPageParams, setModalPageParams]);

  useEffect(() => {
    sendEventGTM(eventsGTM.okpdCommonPageView, { info: setModalPageParams ? 'modal' : 'page' });
  }, [setModalPageParams]);

  return (
    <div className={styles.wrapper}>
      <Flex
        className={styles.filters}
        vertical
        gap={44}
      >
        <Header
          search={search}
          handleSearchChange={handleSearchChange}
        />
        {search && (
          <CharacteristicsFilter
            list={characteristicsOptions}
            isListLoading={isCharacteristicsOptionsLoading}
            characteristicsFilter={characteristicsParams}
            titleText="Уточнить характеристики"
            onCharacteristicsChange={onCharacteristicsChange}
          />
        )}
      </Flex>

      {search ? (
        <div className={styles.content}>
          <OkpdList
            searchString={search}
            characteristics={characteristicsParams}
            setModalPageParams={setModalPageParams}
          />
          <KtruList
            searchString={search}
            characteristics={characteristicsParams}
            setModalPageParams={setModalPageParams}
          />
        </div>
      ) : (
        <InitialPage
          modalPageParams={modalPageParams}
          setModalPageParams={setModalPageParams}
        />
      )}
    </div>
  );
});

OkpdKtruPage.displayName = 'OkpdKtruPage';
