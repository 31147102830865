import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  NmckByIdResponse,
  NmckRow,
  PropositionType,
  UpdatePropositionDTO,
} from '@/api/nmckApi/nmckApiTypes';
import styles from './NmckGridPropositionInput.module.scss';
import cx from 'classnames';
import { NmckGridPropositionInputSuffix } from '@/components/NmckGrid/NmckGridProposition/NmckGridPropositionInput/components/NmckGridPropositionInputSuffix/NmckGridPropositionInputSuffix';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { formatPrice, parsePrice } from '@/utils/formatPrice';
import { Input } from 'antd';
import { debounce } from 'lodash';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';

type Props = {
  columnIndex: number;
  row: NmckRow;
  nmckId: NmckByIdResponse['id'];
};

export const NmckGridPropositionInput = memo(({ row, columnIndex, nmckId }: Props) => {
  const proposition = row.propositions[columnIndex];
  const { updatePropositionWithFallback } = useNmckFallbackDataHandler();

  const isEis = useMemo(() => proposition.type === PropositionType.EIS, [proposition.type]);
  const [price, setPrice] = useState(formatPrice(String(proposition.price)));

  const debouncedUpdateProposition = useMemo(
    () =>
      debounce(
        async (
          updateData: Partial<Pick<UpdatePropositionDTO, 'price' | 'description' | 'forAllRows'>>,
        ) => {
          const { price, description, forAllRows } = updateData;

          sendEventGTM(eventsGTM.nmckRowPriceChange);

          const updatedProposition: UpdatePropositionDTO = {
            type: PropositionType.KP,
            contractId: null,
            price: (price === undefined ? proposition.price : price) ?? null,
            description:
              description ?? proposition.description ?? `Поставщик ${proposition.columnNumber}`,
            forAllRows: forAllRows ?? false,
          };

          await updatePropositionWithFallback(nmckId, row.id, proposition, updatedProposition);
        },
        500,
      ),
    [nmckId, proposition, row.id, updatePropositionWithFallback],
  );

  const handleUpdateProposition = useCallback(
    async (
      updateData: Partial<Pick<UpdatePropositionDTO, 'price' | 'description' | 'forAllRows'>>,
    ) => {
      debouncedUpdateProposition(updateData);
    },
    [debouncedUpdateProposition],
  );

  const handleValueChange = useCallback(
    async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const formattedPrice = formatPrice(value);
      const parsedPrice = parsePrice(formattedPrice);
      setPrice(formattedPrice);

      if (parsedPrice !== proposition.price && !formattedPrice.endsWith(',')) {
        await handleUpdateProposition({ price: parsedPrice });
      }
    },
    [handleUpdateProposition, proposition.price],
  );

  useEffect(() => {
    setPrice(formatPrice(String(proposition.price)));
  }, [proposition.price]);

  return (
    <Input
      value={price}
      disabled={isEis}
      onChange={handleValueChange}
      className={cx(styles.input, {
        [styles.inputWithSuffix]: proposition.description,
      })}
      placeholder="Цена"
      suffix={
        !!proposition.price && (
          <NmckGridPropositionInputSuffix
            nmckId={nmckId}
            proposition={proposition}
            updateProposition={handleUpdateProposition}
          />
        )
      }
    />
  );
});

NmckGridPropositionInput.displayName = 'NmckGridPropositionInput';
