import { memo, useCallback, useMemo } from 'react';
import { useParams } from 'next/navigation';
import { useGetCurrentPurchaseById } from '@/api/planningCurrentApi/planningCurrentApi';
import { Table, TableProps } from 'antd';
import { DescriptionTableDataSource } from '@/widgets/PlanningCurrent/components/DescriptionTab/types';
import {
  DebounceInput,
  DebounceInputNumber,
  DebounceInputNumberUpdatedOptions,
  DebounceInputNumberValueType,
  DebounceInputUpdatedOptions,
  UiDatePicker,
} from '@/components';
import { PurchaseByIdResponse } from '@/api/planningCurrentApi/planningCurrentApiTypes';
import moment, { Moment } from 'moment';
import { isString } from 'lodash';
import {
  DESCRIPTION_TABLE_DATE_PICKER_FIELDS,
  descriptionTableDataSource,
} from '@/widgets/PlanningCurrent/components/DescriptionTab/constants';
import styles from './DescriptionTab.module.scss';
import { UiDatePickerProps } from '@/components/Ui/UiDatePicker/types';
import { updatePurchase } from '@/api/planningAllApi/planningAllApi';
import { formatDate } from '@/utils';
import { dateFormat } from '@/constants';

export const DescriptionTab = memo(() => {
  const params = useParams();
  const purchaseId = Number(params.id);

  const { data, mutate } = useGetCurrentPurchaseById(purchaseId);

  const handleUpdateInputField = useCallback(
    async (value: string, { field }: DebounceInputUpdatedOptions) => {
      if (data) {
        await updatePurchase({ ...data, [field]: value });
        await mutate();
      }
    },
    [data, mutate],
  );

  const handleUpdateInputNumberField = useCallback(
    async (value: DebounceInputNumberValueType, { field }: DebounceInputNumberUpdatedOptions) => {
      if (data) {
        await updatePurchase({ ...data, [field]: value });
        await mutate();
      }
    },
    [data, mutate],
  );

  const datePickerFieldToUpdateHandlerMap = useMemo(
    () =>
      DESCRIPTION_TABLE_DATE_PICKER_FIELDS.reduce(
        (acc, key) => ({
          ...acc,
          [key]: async (date: Moment) => {
            if (data) {
              await updatePurchase({ ...data, [key]: formatDate(date, dateFormat.serverFormat) });
              await mutate();
            }
          },
        }),
        {} as Record<keyof PurchaseByIdResponse, UiDatePickerProps['onChange']>,
      ),
    [data, mutate],
  );

  const columns: TableProps['columns'] = useMemo(
    () => [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Наименование поля',
        width: 352,
      },
      {
        key: 'key',
        dataIndex: 'key',
        title: 'Значение',
        render: (fieldKey: keyof PurchaseByIdResponse, record: DescriptionTableDataSource) => {
          if (!data) {
            return null;
          }

          if (record.fieldType === 'INPUT') {
            return (
              <DebounceInput
                value={data[fieldKey] as string | undefined}
                handleValueChange={handleUpdateInputField}
                field={fieldKey}
                className={styles.input}
                placeholder="Не указано"
              />
            );
          }

          if (record.fieldType === 'INPUT_NUMBER') {
            return (
              <DebounceInputNumber
                value={data[fieldKey] as number | undefined}
                handleValueChange={handleUpdateInputNumberField}
                field={fieldKey}
                className={styles.input}
                placeholder="Не указано"
              />
            );
          }

          const dateValue = isString(data[fieldKey]) ? moment(data[fieldKey] as string) : null;

          return (
            <UiDatePicker
              value={dateValue}
              className={styles.datePicker}
              onChange={datePickerFieldToUpdateHandlerMap[fieldKey]}
            />
          );
        },
      },
    ],
    [data, datePickerFieldToUpdateHandlerMap, handleUpdateInputField, handleUpdateInputNumberField],
  );

  return (
    <Table
      columns={columns}
      dataSource={descriptionTableDataSource}
      pagination={false}
    />
  );
});

DescriptionTab.displayName = 'DescriptionTab';
