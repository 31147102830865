import {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  ConfigProvider,
  ConfigProviderProps,
  Flex,
  Form,
  FormProps,
  Input,
  Radio,
  RadioChangeEvent,
} from 'antd';
import styles from './NmckGridContractsFilter.module.scss';
import { NmckContractsFilter } from '@/components/NmckGrid/NmckGridContracts/types';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  initialContractsFilterState,
  regionTypeOptions,
} from '@/components/NmckGrid/NmckGridContracts/constants';
import { CharacteristicsParam, OptionWithNumberValue } from '@/types/types';
import { CharacteristicForFilter } from '@/api/okpdApi/okpdApiTypes';
import { NmckGridContext } from '@/components/NmckGrid/context/context';
import { ActionType } from '@/components/NmckGrid/context/actions';
import { saveRegionsToStorage } from '@/components/NmckGrid/NmckGridContracts/helpers';
import PlusIcon from '/public/icons/plus.svg';
import MinusIcon from '/public/icons/minus.svg';
import { DatePeriodPicker } from '@/components/DatePeriodPicker/DatePeriodPicker';
import { DatePeriodPickerType } from '@/components/DatePeriodPicker/types';
import { CharacteristicsFilter } from '@/widgets';
import { UnitMeasureSelect } from '@/components/UnitMeasureSelect/UnitMeasureSelect';
import { RegionsTreeSelect } from '@/components/RegionsTreeSelect/RegionsTreeSelect';
import { PurchaseMethodsTreeSelect } from '@/components/PurchaseMethodsTreeSelect/PurchaseMethodsTreeSelect';

const theme: ConfigProviderProps['theme'] = {
  components: {
    Checkbox: {
      colorText: '#6B7886',
    },
    Radio: {
      colorText: '#6B7886',
      buttonCheckedColorDisabled: 'red',
    },
  },
};
const labelCol: FormProps['labelCol'] = { span: 5 };

type Props = {
  filter: NmckContractsFilter;
  setFilter: Dispatch<SetStateAction<NmckContractsFilter>>;
  characteristics?: CharacteristicForFilter[];
  isContractsLoading: boolean;
};

export const NmckGridContractsFilter = memo(
  ({ filter, setFilter, characteristics = [], isContractsLoading }: Props) => {
    const { dispatch } = useContext(NmckGridContext);
    const [form] = Form.useForm();
    const [isAllFieldsShown, setIsAllFieldsShown] = useState(false);

    const handleChange = useCallback(
      (
        e: ChangeEvent<HTMLInputElement> | RadioChangeEvent | CheckboxChangeEvent,
        field: keyof NmckContractsFilter,
      ) => {
        let value;
        let type;
        if ('target' in e) {
          value = e.target.value;
          type = e.target.type;
        } else {
          value = e;
        }

        if (type === 'checkbox') {
          setFilter((prev) => ({ ...prev, [field]: e.target.checked }));
        } else if (type === 'radio') {
          setFilter((prev) => ({ ...prev, [field]: value }));
        } else {
          setFilter((prev) => ({ ...prev, [field]: value }));
        }
        dispatch({ type: ActionType.TOKEN_UPDATE, payload: null });
      },
      [dispatch, setFilter],
    );

    const handleRegionsChange = useCallback(
      (values: number[]) => {
        setFilter((prev) => ({ ...prev, regionIds: values }));
        dispatch({ type: ActionType.TOKEN_UPDATE, payload: null });
      },
      [dispatch, setFilter],
    );

    const handlePurchaseMethodsChange = useCallback(
      (values: number[]) => {
        setFilter((prev) => ({ ...prev, purchaseMethodIds: values }));
        dispatch({ type: ActionType.TOKEN_UPDATE, payload: null });
      },
      [dispatch, setFilter],
    );

    const handleCharacteristicsChange = useCallback(
      (newChars: CharacteristicsParam[]) => {
        setFilter((prev) => ({
          ...prev,
          characteristics: newChars,
        }));
        dispatch({ type: ActionType.TOKEN_UPDATE, payload: null });
      },
      [dispatch, setFilter],
    );

    const handleUnitChange = useCallback(
      (option?: OptionWithNumberValue) => {
        setFilter((prev) => ({
          ...prev,
          unitMeasure: option?.value,
        }));
        dispatch({ type: ActionType.TOKEN_UPDATE, payload: null });
      },
      [dispatch, setFilter],
    );

    const resetAll = useCallback(() => {
      form.resetFields();
      setFilter(initialContractsFilterState);
      dispatch({ type: ActionType.TOKEN_UPDATE, payload: null });
      saveRegionsToStorage([]);
    }, [dispatch, form, setFilter]);

    const keyToDateChangeHandlerMap = useMemo(
      () =>
        ['executionDate', 'conclusionDate'].reduce(
          (acc, key: string) => ({
            ...acc,
            [key]: ({ startDate, endDate }: DatePeriodPickerType['updatedDates']) => {
              if (key === 'executionDate') {
                setFilter((prev) => ({
                  ...prev,
                  executionDateStart: startDate,
                  executionDateEnd: endDate,
                }));
              }

              if (key === 'conclusionDate') {
                setFilter((prev) => ({
                  ...prev,
                  conclusionDateStart: startDate,
                  conclusionDateEnd: endDate,
                }));
              }

              dispatch({ type: ActionType.TOKEN_UPDATE, payload: null });
            },
          }),
          {} as Record<
            'executionDate' | 'conclusionDate',
            (updatedDates: DatePeriodPickerType['updatedDates']) => void
          >,
        ),
      [dispatch, setFilter],
    );

    const handleToggleFieldsShown = useCallback(() => setIsAllFieldsShown((prev) => !prev), []);

    return (
      <ConfigProvider theme={theme}>
        <div className={styles.wrapper}>
          <div className={styles.searchWrapper}>
            <div className={styles.search}>
              <h3 className={styles.title}>Поиск исполненных без неустоек контрактов</h3>
              <Form
                form={form}
                labelAlign={'left'}
                labelCol={labelCol}
                layout="horizontal"
                className={styles.form}
              >
                <Form.Item label="Регион:">
                  <Flex
                    gap={25}
                    align="center"
                  >
                    <RegionsTreeSelect
                      value={filter.regionIds}
                      className={styles.treeSelect}
                      onRegionsChange={handleRegionsChange}
                    />
                    <Radio.Group
                      value={filter.regionType}
                      name="regionType"
                      onChange={(e) => handleChange(e, 'regionType')}
                      options={regionTypeOptions}
                    />
                  </Flex>
                </Form.Item>
                <Form.Item label="Срок исполнения:">
                  <DatePeriodPicker
                    onDatesChange={keyToDateChangeHandlerMap['executionDate']}
                    startDate={filter.executionDateStart}
                    endDate={filter.executionDateEnd}
                  />
                </Form.Item>
                {isAllFieldsShown && (
                  <>
                    <Form.Item label="Способ закупки:">
                      <PurchaseMethodsTreeSelect
                        className={styles.treeSelect}
                        onPurchaseMethodsChange={handlePurchaseMethodsChange}
                      />
                    </Form.Item>
                    <Form.Item label="Цена, ₽:">
                      <Flex gap={16}>
                        <Form.Item
                          noStyle
                          name="priceFrom"
                        >
                          <Input
                            min={0}
                            type="number"
                            value={filter.priceFrom}
                            placeholder="Цена, от"
                            className={styles.priceFromInput}
                            onBlur={(e) => handleChange(e, 'priceFrom')}
                          />
                        </Form.Item>
                        <Form.Item
                          noStyle
                          name="priceUpTo"
                        >
                          <Input
                            min={0}
                            type="number"
                            value={filter.priceUpTo}
                            placeholder="Цена, до"
                            className={styles.priceUpToInput}
                            onBlur={(e) => handleChange(e, 'priceUpTo')}
                          />
                        </Form.Item>
                      </Flex>
                    </Form.Item>
                    <Form.Item
                      label="Единица измерения:"
                      name="unit"
                    >
                      <UnitMeasureSelect
                        allowClear
                        className={styles.unitSelect}
                        placeholder="Выберите единицу измерения"
                        onChange={handleUnitChange}
                      />
                    </Form.Item>
                    <Form.Item label="Заключен:">
                      <DatePeriodPicker
                        onDatesChange={keyToDateChangeHandlerMap['conclusionDate']}
                        startDate={filter.conclusionDateStart}
                        endDate={filter.conclusionDateEnd}
                      />
                    </Form.Item>
                    <Form.Item label="Количество:">
                      <Flex gap={16}>
                        <Form.Item
                          noStyle
                          name="deliveredFrom"
                        >
                          <Input
                            value={filter.countFrom}
                            min={0}
                            type="number"
                            placeholder="От"
                            className={styles.deliveredFromInput}
                            onBlur={(e) => handleChange(e, 'countFrom')}
                          />
                        </Form.Item>
                        <Form.Item
                          noStyle
                          name="deliveredUpTo"
                        >
                          <Input
                            value={filter.countUpTo}
                            min={0}
                            type="number"
                            placeholder="До"
                            className={styles.deliveredUpToInput}
                            onBlur={(e) => handleChange(e, 'countUpTo')}
                          />
                        </Form.Item>
                      </Flex>
                    </Form.Item>
                  </>
                )}{' '}
              </Form>
            </div>
            <CharacteristicsFilter
              list={characteristics}
              isListLoading={isContractsLoading}
              characteristicsFilter={filter.characteristics}
              maxVisibleCountRows={isAllFieldsShown ? undefined : 2}
              addButtonPosition="underList"
              headerJustify="space-between"
              selectClassName={styles.characteristicsSelect}
              onCharacteristicsChange={handleCharacteristicsChange}
              onReset={resetAll}
            />
          </div>
          <Button
            icon={isAllFieldsShown ? <MinusIcon /> : <PlusIcon />}
            type="link"
            onClick={handleToggleFieldsShown}
            className={styles.toggleShownButton}
          >
            {isAllFieldsShown ? 'Меньше фильтров' : 'Больше фильтров'}
          </Button>
        </div>
      </ConfigProvider>
    );
  },
);

NmckGridContractsFilter.displayName = 'NmckGridContractsFilter';
