import { Actions, ActionType } from './actions';
import { NmckGridState } from '@/components/NmckGrid/context/types';

export const nmckGridReducer = (state: NmckGridState, action: Actions): NmckGridState => {
  switch (action.type) {
    case ActionType.PAGE_SIZE_UPDATE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case ActionType.TOKEN_UPDATE:
      return {
        ...state,
        token: action.payload,
      };
    case ActionType.NMCK_ID_UPDATE:
      return {
        ...state,
        nmckId: action.payload,
      };
    default:
      return state;
  }
};
