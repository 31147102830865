import {
  BadgeType,
  CalculationMethod,
  NmckByIdResponse,
  NmckType,
  PropositionType,
  VariationColor,
} from '@/api/nmckApi/nmckApiTypes';

export const NMCK_ANALYSIS_BY_ID_FALLBACK: NmckByIdResponse = {
  id: 0,
  versionNumber: 0,
  type: NmckType.MARKET,
  created: '2025-01-14T16:51:55.781884',
  modified: '2025-01-14T16:51:55.781918',
  nmckTotal: 0,
  name: '',
  generatedName: '',
  responsible: '',
  rows: [
    {
      id: 1,
      name: '',
      code: null,
      codeError: null,
      propositions: [
        {
          columnNumber: 1,
          type: PropositionType.KP,
          price: null,
          description: null,
          contractId: null,
          forAllRows: false,
          contractNumber: null,
        },
        {
          columnNumber: 2,
          type: PropositionType.KP,
          price: null,
          description: null,
          contractId: null,
          forAllRows: false,
          contractNumber: null,
        },
        {
          columnNumber: 3,
          type: PropositionType.KP,
          price: null,
          description: null,
          contractId: null,
          forAllRows: false,
          contractNumber: null,
        },
      ],
      unit: '',
      unitCode: 0,
      variation: 0,
      variationColor: VariationColor.GREEN,
      amount: 0,
      averagePrice: 0,
      minPrice: 0,
      nmck: 0,
      nkmiId: null,
      nds: 0,
    },
  ],
  calculationPrecision: null,
  calculationMethod: CalculationMethod.AVG,
  badge: BadgeType.NO_BADGE,
  recurrency: 0,
  recurrencyBadge: null,
};
