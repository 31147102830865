import { MenuItem, MenuItemInMap } from '@/components/Navigation/type';
import { PageRouteValuesType } from '@/app/pageRoutes';

export const createMenuMap = (menuList: MenuItem[]): Record<PageRouteValuesType, MenuItemInMap> => {
  return menuList.reduce(
    (acc, item) => {
      acc[item.href] = {
        ...item,
        childrenMap: item.children ? createMenuMap(item.children) : undefined,
      };
      return acc;
    },
    {} as Record<PageRouteValuesType, MenuItemInMap>,
  );
};
