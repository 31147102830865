import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DebounceInputNumber, DebounceInputNumberValueType } from '@/components';
import { AutoComplete, Empty, Flex, Spin, Tooltip } from 'antd';
import { useGetKtru } from '@/api/nmckApi/nmckApi';
import { NmckByIdResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { KtruResult } from '@/api/okpdApi/okpdApiTypes';
import styles from './NmckGridNkmiAutocomplete.module.scss';
import { InputNumberRef } from 'rc-input-number';
import { getFormattedNkmi } from '@/widgets/OkpdKtru/utils/utils';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';

type Props = {
  nmckId: NmckByIdResponse['id'];
  row: NmckRow;
};

const renderOption = (option: KtruResult) => (
  <Flex align="center">
    <div>{`${getFormattedNkmi(option.nkmis) ?? ''} (${option.code}) –`}&nbsp;</div>
    <Tooltip
      arrow={false}
      title={option.name}
      placement="right"
    >
      <div className={styles.autocompleteName}>{option.name}</div>
    </Tooltip>
  </Flex>
);

const notFoundContent = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

export const NmckGridNkmiAutocomplete = memo(({ nmckId, row }: Props) => {
  const [nkmiValue, setNkmiValue] = useState(row.nkmiId);
  const [searchString, setSearchString] = useState<NmckRow['nkmiId']>(row.nkmiId);
  const inputRef = useRef<InputNumberRef>(null);

  const { updateNmckRowWithFallback } = useNmckFallbackDataHandler();
  const { data, isLoading } = useGetKtru({ nkmiId: searchString });

  const options = useMemo(
    () =>
      data?.results.map((item) => ({
        value: item.code.toString(),
        data: item,
        label: renderOption(item),
      })) ?? [],
    [data?.results],
  );

  const handleUpdateRow = useCallback(
    async ({ nkmis, code }: KtruResult) => {
      await updateNmckRowWithFallback(nmckId, { ...row, nkmiId: getFormattedNkmi(nkmis), code });
    },
    [nmckId, row, updateNmckRowWithFallback],
  );

  const onSelect = useCallback(
    async (_: unknown, { data }: { data: KtruResult }) => {
      inputRef.current?.blur();
      setNkmiValue(getFormattedNkmi(data.nkmis));
      await handleUpdateRow(data);
    },
    [handleUpdateRow],
  );

  const handleInputChange = useCallback((value: DebounceInputNumberValueType) => {
    setSearchString(value);
  }, []);

  useEffect(() => {
    setSearchString(row.nkmiId);
    setNkmiValue(row.nkmiId);
  }, [row.nkmiId]);

  return (
    <AutoComplete
      options={options}
      popupMatchSelectWidth={options.length ? 500 : 250}
      value={nkmiValue}
      onSelect={onSelect}
      className={styles.autocompleteWrapper}
      notFoundContent={notFoundContent}
    >
      <DebounceInputNumber
        className={styles.input}
        handleValueChange={handleInputChange}
        suffix={isLoading ? <Spin size="small" /> : null}
        controls={false}
        ref={inputRef}
      />
    </AutoComplete>
  );
});

NmckGridNkmiAutocomplete.displayName = 'NmckGridNkmiAutocomplete';
