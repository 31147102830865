import React, { createContext, useReducer } from 'react';
import { nmckGridReducer } from './reducer';
import { Actions } from '@/components/NmckGrid/context/actions';
import { DEFAULT_PAGE_SIZE } from '@/components/Table/TablePagination/constants';
import { NmckGridState } from '@/components/NmckGrid/context/types';

const initialState: NmckGridState = {
  pageSize: DEFAULT_PAGE_SIZE,
  token: null,
  hasMoreResults: false,
  nmckId: 0,
};
const NmckGridContext = createContext<{
  state: NmckGridState;
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const NmckGridProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(nmckGridReducer, initialState);

  return (
    <NmckGridContext.Provider value={{ state, dispatch }}>{children}</NmckGridContext.Provider>
  );
};

export { NmckGridContext, NmckGridProvider };
