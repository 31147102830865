import React, { memo, useCallback, useMemo } from 'react';
import { Button, Popover } from 'antd';
import DotsIcon from '/public/icons/dots.svg';
import { NmckByIdResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { deleteNmckRow, useGetNmckById } from '@/api/nmckApi/nmckApi';
import styles from './More.module.scss';
import { MorePopUpContent } from './components/MorePopUpContent/MorePopUpContent';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';

type Props = {
  record: NmckRow;
  nmckId: NmckByIdResponse['id'];
};

export const More = memo(({ record, nmckId }: Props) => {
  const { createCopyNmck } = useNmckFallbackDataHandler();
  const { mutate: refreshCalculation, data } = useGetNmckById(nmckId);

  const rows = useMemo(() => data?.rows ?? [], [data]);

  const handleDeleteRow = useCallback(async () => {
    await deleteNmckRow(nmckId, record.id);
    await refreshCalculation();
  }, [nmckId, record.id, refreshCalculation]);

  const handleCopyRow = useCallback(async () => {
    await createCopyNmck(nmckId, record.id);
  }, [createCopyNmck, nmckId, record.id]);

  return (
    <Popover
      content={
        <MorePopUpContent
          rows={rows}
          record={record}
          handleCopyRow={handleCopyRow}
          handleDeleteRow={handleDeleteRow}
        />
      }
      placement="leftTop"
    >
      <Button
        icon={<DotsIcon />}
        type="link"
        size="small"
        className={styles.button}
      />
    </Popover>
  );
});
More.displayName = 'More';
