import { memo, useCallback, useContext, useMemo } from 'react';
import { Flex } from 'antd';
import { ReestrDirectPageResultsTable } from './components/ReestrDirectPageResultsTable/ReestrDirectPageResultsTable';
import { resultsTableColumns } from './data';
import styles from './ReestrDirectPageResults.module.scss';
import {
  DirectContract,
  DirectContractCauses,
  DirectContractsResponse,
} from '@/api/reestrDirectApi/reestrDirectApiTypes';
import { ReestrDirectContext } from '@/widgets/ReestrDirect/context/context';
import { actions } from '@/widgets/ReestrDirect/context/actions';
import { getXlsx } from '@/api/reestrDirectApi/reestrDirectApi';
import { useSortableTableColumns } from '@/components/Table/TableColumnsSettings/hooks';
import { ExportFile } from '@/components/ExportFile/ExportFile';
import { TableFooter, TableFooterProps } from '@/components/Table/TableFooter/TableFooter';

type ReestrDirectPageResultsProps = {
  contracts: DirectContractsResponse | undefined;
  isContractsLoading: boolean;
  refreshContracts: () => void;
  causes: DirectContractCauses | undefined;
  isCausesLoading: boolean;
};

export const ReestrDirectPageResults = memo(
  ({
    contracts,
    isContractsLoading,
    refreshContracts,
    causes,
    isCausesLoading,
  }: ReestrDirectPageResultsProps) => {
    const { state, dispatch } = useContext(ReestrDirectContext);

    const { orderedColumns, orderedColumnsMap, setOrderedColumnsMap } =
      useSortableTableColumns<DirectContract>({
        columns: resultsTableColumns,
      });
    const filteredOrderedColumns = useMemo(
      () => orderedColumns.filter((col) => col.title),
      [orderedColumns],
    );

    const handlePageSizeChange = useCallback(
      (pageSize: number) => {
        dispatch(actions.paginationUpdate({ ...state.pagination, pageSize }));
      },
      [dispatch, state.pagination],
    );

    const handlePageNumberChange = useCallback(
      (page: number) => {
        dispatch(actions.paginationUpdate({ ...state.pagination, pageNumber: page }));
      },
      [dispatch, state.pagination],
    );

    const tableFooterProps: TableFooterProps<DirectContract> = useMemo(
      () => ({
        pageBasedPagination: {
          pagination: { ...state.pagination },
          count: contracts?.count ?? 0,
          onPageSizeChange: handlePageSizeChange,
          onPageNumberChange: handlePageNumberChange,
        },
        columnsSettings: {
          columns: filteredOrderedColumns,
          orderedColumnsMap,
          setOrderedColumnsMap,
        },
      }),
      [
        contracts?.count,
        filteredOrderedColumns,
        handlePageNumberChange,
        handlePageSizeChange,
        orderedColumnsMap,
        setOrderedColumnsMap,
        state.pagination,
      ],
    );

    return (
      <div className={styles.wrapper}>
        <ReestrDirectPageResultsTable
          contracts={contracts}
          isContractsLoading={isContractsLoading}
          selectedColumns={orderedColumns}
          refreshContracts={refreshContracts}
          causes={causes}
          isCausesLoading={isCausesLoading}
        />
        <Flex
          align="center"
          justify="space-between"
          gap={16}
          className={styles.settings}
        >
          <TableFooter {...tableFooterProps} />
          <ExportFile
            onExportButtonClick={getXlsx}
            flexGap={24}
          />
        </Flex>
      </div>
    );
  },
);
ReestrDirectPageResults.displayName = 'ReestrDirectPageResults';
