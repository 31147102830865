'use client';

import { memo } from 'react';
import { NmckGridProvider } from '@/components/NmckGrid/context/context';
import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { NmckAnalysisPageContent } from '@/widgets/NmckAnalysis/NmckAnalysisPageContent';

type Props = {
  nmckId: NmckByIdResponse['id'];
};

export const NmckAnalysisPage = memo(({ nmckId }: Props) => {
  return (
    <NmckGridProvider>
      <NmckAnalysisPageContent nmckId={nmckId} />
    </NmckGridProvider>
  );
});

NmckAnalysisPage.displayName = 'NmckAnalysisPage';
