import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Flex } from 'antd';
import PlusIcon from '/public/icons/plus.svg';
import CrossIcon from '/public/icons/cross.svg';
import { BDUIFilterItem } from '@/components/BDUIFilter/components/BDUIFilterItem/BDUIFilterItem';
import {
  BDUIComponentType,
  BDUIFilterItemType,
  BDUIFilterParam,
  BDUIFilterResponse,
} from '@/components/BDUIFilter/types';
import { Option } from '@/types/types';
import { differenceBy, isEqual } from 'lodash';
import styles from './BDUIFilter.module.scss';
import {
  getAllUniqueItems,
  getItemsMapByBDUIFiltersResponse,
  getItemWithDefaultsValues,
  getNameOptions,
  getParamsByItems,
} from '@/components/BDUIFilter/utils';
import cx from 'classnames';

type FiltersProps = {
  filtersResponse: BDUIFilterResponse[];
  onFiltersChange: (newFilters: BDUIFilterParam[]) => void;
  currentFilterParams: BDUIFilterParam[];

  isFiltersLoading: boolean;
  wrapperClassName?: string;

  // Для использования в НМЦК нужно передавать rowId в запрос ед. измерений
  rowId?: number;
};

export const BDUIFilter = memo(
  ({
    onFiltersChange,
    filtersResponse,
    currentFilterParams,
    isFiltersLoading,
    wrapperClassName,
    rowId,
  }: FiltersProps) => {
    const isFiltersChanged = useRef(false);
    const idCounter = useRef<number>(Math.random());
    const itemsWithEmptyValues = useRef<BDUIFilterItemType[]>([]);
    const itemsWithEmptyValuesMap = useRef<Record<string, BDUIFilterItemType | undefined>>({});

    const [nameOptions, setNameOptions] = useState<Option[]>([]);
    const [valueOptionsMap, setValueOptionsMap] = useState<Record<string, Option[]>>({});
    const [items, setItems] = useState<BDUIFilterItemType[]>([
      { id: idCounter.current, type: BDUIComponentType.EMPTY },
    ]);

    const isAddDisabled = useMemo(() => {
      const lastItem = items.at(-1);
      return !lastItem?.name || items.length === filtersResponse.length;
    }, [filtersResponse.length, items]);

    const availableNameOptions = useMemo(() => {
      const selectedNameOptions = items.map(({ name }) => name).filter(Boolean) as Option[];
      return differenceBy(nameOptions, selectedNameOptions, 'label');
    }, [items, nameOptions]);

    useEffect(() => {
      if (!isFiltersChanged.current) {
        setNameOptions(getNameOptions(filtersResponse));
        setValueOptionsMap(getItemsMapByBDUIFiltersResponse(filtersResponse));
      }
    }, [filtersResponse]);

    useEffect(() => {
      if (isFiltersChanged.current) {
        const filterParams = getParamsByItems(
          getAllUniqueItems(items, itemsWithEmptyValues.current),
        );

        if (!isEqual(currentFilterParams, filterParams)) {
          onFiltersChange(filterParams);
        }
      }
    }, [currentFilterParams, filtersResponse, items, onFiltersChange]);

    useEffect(() => {
      if (!isFiltersChanged.current && filtersResponse.length) {
        const items = getItemWithDefaultsValues(filtersResponse);
        const itemsWithoutValues = items.map((item) => ({
          ...item,
          value: {
            type: item.value!.type,
          },
        }));

        setItems((prev) => (items.length ? items : prev));
        itemsWithEmptyValues.current = itemsWithoutValues;
        itemsWithEmptyValuesMap.current = itemsWithEmptyValues.current.reduce(
          (acc, item) => {
            if (item.name?.value) {
              acc[item.name.value] = item;
            }
            return acc;
          },
          {} as Record<string, BDUIFilterItemType>,
        );
      }
    }, [filtersResponse, nameOptions]);

    const handleItemChange = useCallback((item: BDUIFilterItemType) => {
      isFiltersChanged.current = true;
      setItems((prev) =>
        prev.map((prevItem) =>
          item.id === prevItem.id
            ? {
                ...item,
                emptyValues: itemsWithEmptyValuesMap.current[item.name?.value ?? '']?.emptyValues,
              }
            : prevItem,
        ),
      );
    }, []);

    const handleAddItem = useCallback(() => {
      isFiltersChanged.current = true;
      setItems((prev) => [...prev, { id: ++idCounter.current, type: BDUIComponentType.EMPTY }]);
    }, []);

    const handleDeleteItem = useCallback(
      (item: BDUIFilterItemType) => {
        isFiltersChanged.current = true;
        setItems((prev) => {
          if (prev.length === 1) {
            return [{ id: 1, type: BDUIComponentType.EMPTY }];
          }
          return prev.filter(({ id }) => id !== item.id);
        });
        if (items.length === 1) {
          idCounter.current = 1;
        }
      },
      [items],
    );

    const handleResetAll = useCallback(() => {
      isFiltersChanged.current = true;
      idCounter.current = 1;
      setItems([{ id: 1, type: BDUIComponentType.EMPTY }]);
    }, []);

    return (
      <Flex
        vertical
        gap={16}
        className={wrapperClassName}
      >
        <Flex
          align="center"
          gap={32}
        >
          <div className={styles.title}>Фильтры</div>
          <Button
            type="link"
            size="small"
            icon={<CrossIcon />}
            className={styles.button}
            onClick={handleResetAll}
            disabled={!filtersResponse.length}
          >
            Сбросить все
          </Button>
        </Flex>
        <Flex
          vertical
          gap={20}
        >
          {items.map((item) => (
            <BDUIFilterItem
              key={item.id}
              values={valueOptionsMap}
              names={availableNameOptions}
              isOptionsLoading={isFiltersLoading}
              isFiltersChanged={isFiltersChanged.current}
              item={item}
              onChangeItem={handleItemChange}
              onDeleteItem={handleDeleteItem}
              rowId={rowId}
            />
          ))}
        </Flex>
        <Button
          type="link"
          icon={<PlusIcon />}
          className={cx(styles.button, styles.buttonAdd)}
          onClick={handleAddItem}
          disabled={isAddDisabled}
        >
          Добавить фильтр
        </Button>
      </Flex>
    );
  },
);

BDUIFilter.displayName = 'BDUIFilter';
