import { Button, ConfigProvider, ConfigProviderProps, Flex, Modal, Space } from 'antd';
import { memo, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import {
  getNmckCompatibilityXlsx,
  splitNmckById,
  updateCompatibilityCell,
  useGetNmckById,
  useGetNmckCompatibilityById,
} from '@/api/nmckApi/nmckApi';
import {
  BadgeType,
  NmckByIdResponse,
  NmckCompatibilityByIdResponse,
} from '@/api/nmckApi/nmckApiTypes';
import { pageRoutes } from '@/app/pageRoutes';
import { setSplittedNmckIds } from '@/utils';
import ExportIcon from '/public/icons/export.svg';
import DivideIcon from '/public/icons/divide.svg';
import colors from '@/styles/colors.module.scss';
import styles from './IncompatibleModal.module.scss';
import { CompatibilityTable } from '@/components/Compatibility/CompatibilityTable/CompatibilityTable';
import { CompatibilityFilter } from '@/components/Compatibility/CompatibilityFilter/CompatibilityFilter';
import { useCompatibility } from '@/components/Compatibility/useCompatibility';
import { CompatibilityWarning } from '@/components/Compatibility/CompatibilityWarning/CompatibilityWarning';

type IncompatibleModalProps = {
  nmckId: NmckByIdResponse['id'];
  name: string;
  open: boolean;
  onCancel: () => void;
};

const theme: ConfigProviderProps['theme'] = {
  components: {
    Table: {
      rowExpandedBg: colors.white,
    },
  },
};

export const IncompatibleModal = memo(
  ({ open, onCancel, nmckId, name }: IncompatibleModalProps) => {
    const router = useRouter();
    const [compatibility, setCompatibility] = useState<NmckCompatibilityByIdResponse>();
    const {
      rows,
      rawRows,
      header,
      activeTab,
      showEmptyRows,
      isNationalProjects,
      setActiveTab,
      setShowEmptyRows,
      setIsNationalProjects,
    } = useCompatibility({ data: compatibility });

    const { data, isLoading, mutate } = useGetNmckCompatibilityById(
      nmckId,
      !showEmptyRows,
      isNationalProjects,
    );
    const { data: nmckData } = useGetNmckById(nmckId);

    const hasIncompatibleCode = nmckData?.badge === BadgeType.HAS_INCOMPATIBLE_CODES;

    useEffect(() => {
      setCompatibility(data);
    }, [data]);

    useEffect(() => {
      if (open) {
        mutate();
      }
    }, [mutate, open]);

    const getReport = useCallback(() => {
      getNmckCompatibilityXlsx(nmckId, name, showEmptyRows, isNationalProjects);
    }, [showEmptyRows, isNationalProjects, name, nmckId]);

    const handleSplit = useCallback(async () => {
      const splitIds = await splitNmckById(nmckId, isNationalProjects);
      setSplittedNmckIds(splitIds);
      router.push(pageRoutes.nmckHistory);
    }, [nmckId, isNationalProjects, router]);

    return (
      <ConfigProvider theme={theme}>
        <Modal
          onCancel={onCancel}
          open={open}
          width={'95%'}
          footer={null}
          centered
          className={styles.modal}
        >
          <div className={styles.wrapper}>
            <Flex
              gap={64}
              className={styles.header}
            >
              <div className={styles.title}>Несовместимые коды ОКПД2/КТРУ</div>
              <Flex
                gap={40}
                align="center"
                className={styles.buttons}
              >
                <Space className={styles.excel}>
                  <ExportIcon className={styles.exportIcon} />
                  <p onClick={getReport}>Сохранить в Excel</p>
                </Space>
              </Flex>
              <Button
                type="primary"
                icon={<DivideIcon />}
                className={styles.button}
                onClick={handleSplit}
                disabled={!hasIncompatibleCode}
              >
                Разделить закупку
              </Button>
            </Flex>
            <div className={styles.main}>
              <CompatibilityWarning hasRestrictions={hasIncompatibleCode} />

              <CompatibilityFilter
                activeKey={activeTab}
                setActiveKey={setActiveTab}
                rows={rawRows}
                isNationalProjects={isNationalProjects}
                setIsNationalProjects={setIsNationalProjects}
                showEmptyRows={showEmptyRows}
                setShowEmptyRows={setShowEmptyRows}
                className={styles.filterWrapper}
              />
              <CompatibilityTable
                id={nmckId}
                rows={rows}
                header={header}
                isLoading={isLoading}
                refreshTableData={mutate}
                updateCell={updateCompatibilityCell}
                withVerticalScroll
              />
            </div>
          </div>
        </Modal>
      </ConfigProvider>
    );
  },
);

IncompatibleModal.displayName = 'IncompatibleModal';
