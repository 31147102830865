import CopyIcon from 'public/icons/copy.svg';
import styles from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersTable/OkpdKtruSuppliersTable.module.scss';
import { Flex, TableProps, Typography } from 'antd';
import { SupplierResult } from '@/api/okpdApi/okpdApiTypes';
import { formatPrice } from '@/utils/formatPrice';
import Link from 'next/link';
import { preventEventPropagation } from '@/utils/preventEventPropagation';
import ExportDiagonalIcon from '/public/icons/exportDiagonal.svg';

export const copyable = {
  tooltips: ['Нажмите чтобы скопировать', 'Скопировано'],
  icon: [
    <CopyIcon
      key="copy-icon"
      className={styles.copyIcon}
    />,
  ],
};

export const tableScroll = {
  x: 'max-content',
};

export const tableColumns: TableProps<SupplierResult>['columns'] = [
  {
    title: 'Поставщик',
    key: 'name',
    width: '480px',
    render: (_: unknown, { name, inn, kpp, address }: SupplierResult) => (
      <Flex
        vertical
        gap={4}
      >
        <div className={styles.name}>{name}</div>
        <Flex
          align="center"
          gap={4}
          wrap
        >
          <div className={styles.gray}>ИНН {inn}</div>
          <div className={styles.gray}>КПП {kpp}</div>
          <div className={styles.gray}>{address}</div>
        </Flex>
      </Flex>
    ),
  },
  {
    title: 'Исполнено всего',
    key: 'contractsFinished',
    width: '200px',
    align: 'center' as const,
    ellipsis: true,
  },
  {
    title: 'Последний исполненный контракт',
    key: 'price',
    width: '300px',
    render: (_: unknown, { price, lastContractRegNum }: SupplierResult) => (
      <Flex
        gap={16}
        align="center"
        justify="flex-end"
      >
        <span>{formatPrice(price)}</span>
        <Link
          href={`https://zakupki.gov.ru/epz/contract/contractCard/common-info.html?reestrNumber=${lastContractRegNum}`}
          target="_blank"
          className={styles.linkRegNum}
          onClick={preventEventPropagation}
        >
          <ExportDiagonalIcon />
          <span>Последний исп. контракт</span>
        </Link>
      </Flex>
    ),
  },
  {
    title: 'E-mail',
    key: 'email',
    width: '260px',
    render: (email: string) => (
      <Typography.Text
        copyable={copyable}
        className={styles.copyable}
        onClick={preventEventPropagation}
      >
        {email}
      </Typography.Text>
    ),
  },
  {
    title: 'Телефон',
    key: 'phone',
    render: (phone: string) => (
      <Typography.Text
        copyable={copyable}
        className={styles.copyable}
        onClick={preventEventPropagation}
      >
        {phone}
      </Typography.Text>
    ),
  },
].map((c) => ({ ...c, dataIndex: c.key }));
