import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './CompatibilityTable.module.scss';
import { Button, ConfigProvider, ConfigProviderProps, Flex, Table, TableProps } from 'antd';
import {
  NmckByIdResponse,
  NmckCompatibilityByIdResponse,
  NmckCompatibilityCell,
  NmckCompatibilityHeader,
  NmckCompatibilityRow,
  NPACellType,
} from '@/api/nmckApi/nmckApiTypes';
import { staticColumns } from '@/components/Compatibility/constants';
import CheckRoundNotFilledIcon from '/public/icons/checkRoundNotFilled.svg';
import RestrictIcon from '/public/icons/restrictNotFilled.svg';
import { CompatibilityTableCell } from '@/components/Compatibility/CompatibilityTable/CompatibilityTableCell/CompatibilityTableCell';
import {
  CompatibilityUpdateBody,
  PurchaseByIdResponse,
} from '@/api/planningCurrentApi/planningCurrentApiTypes';
import { KeyedMutator } from 'swr';
import cx from 'classnames';

type Props = {
  id: NmckByIdResponse['id'] | PurchaseByIdResponse['id'];
  rows: NmckCompatibilityRow[];
  header: NmckCompatibilityHeader[];
  isLoading: boolean;
  updateCell: (
    id: NmckByIdResponse['id'] | PurchaseByIdResponse['id'],
    rowId: number,
    body: CompatibilityUpdateBody,
  ) => Promise<unknown>;
  refreshTableData: KeyedMutator<NmckCompatibilityByIdResponse>;
  withVerticalScroll?: boolean;
};

const theme: ConfigProviderProps['theme'] = {
  components: {
    Table: {
      cellPaddingInline: 5,
    },
  },
};

const scroll = {
  x: 'max-content',
};

export const CompatibilityTable = memo(
  ({ id, rows, header, isLoading, updateCell, refreshTableData, withVerticalScroll }: Props) => {
    const columnCodeToCellMap: Record<number, Record<string, NmckCompatibilityCell>> = useMemo(
      () =>
        rows.reduce(
          (acc, row) => ({
            ...acc,
            [row.id]: row.cells.reduce(
              (cellsAcc, cell) => ({
                ...cellsAcc,
                [cell.columnCode]: cell,
              }),
              {},
            ),
          }),
          {},
        ),
      [rows],
    );

    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
    const [currentExpandableCell, setCurrentExpandableCell] = useState<
      NmckCompatibilityCell | undefined
    >();

    const expandReset = useCallback(() => {
      setCurrentExpandableCell(undefined);
      setExpandedRowKeys([]);
    }, []);

    const handleExpandClick = useCallback(
      (record: NmckCompatibilityRow, cell: NmckCompatibilityCell) => {
        const isSameCell =
          expandedRowKeys[0] === record.id && currentExpandableCell?.columnCode === cell.columnCode;

        if (isSameCell) {
          expandReset();
        } else {
          setCurrentExpandableCell(cell);
          setExpandedRowKeys([record.id]);
        }
      },
      [currentExpandableCell?.columnCode, expandReset, expandedRowKeys],
    );

    const columns: TableProps<NmckCompatibilityRow>['columns'] = [
      ...staticColumns!,
      ...header.map((item) => ({
        title: item.caption,
        className: styles.parrentRow,
        children: item.columns.map((column) => ({
          title: <p className={styles.columnTitle}>{column.title}</p>,
          className: styles.childRow,
          render: (_: unknown, record: NmckCompatibilityRow) => {
            const cell = columnCodeToCellMap[record.id][column.code];

            return (
              <CompatibilityTableCell
                cell={cell}
                record={record}
                onClick={handleExpandClick}
                currentExpandRow={expandedRowKeys[0]}
                currentExpandCell={currentExpandableCell}
              />
            );
          },
        })),
      })),
    ];

    const handleUpdateCompatibilityCell = useCallback(
      async (cellCode: string | undefined, accepted: boolean) => {
        if (expandedRowKeys[0] && cellCode) {
          await updateCell(id, expandedRowKeys[0], {
            npaShortName: cellCode,
            accepted,
          });
          expandReset();
          await refreshTableData();
        }
      },
      [expandReset, expandedRowKeys, id, refreshTableData, updateCell],
    );

    const expandedRowRender = useCallback(
      () =>
        currentExpandableCell && (
          <Flex
            vertical
            gap={24}
            className={styles.expandableContent}
          >
            {currentExpandableCell.text && (
              <div
                className={styles.expandableText}
                dangerouslySetInnerHTML={{ __html: currentExpandableCell.text }}
              />
            )}
            {currentExpandableCell.type === NPACellType.SELECT && (
              <Flex
                gap={20}
                align="center"
              >
                <Button
                  className={styles.acceptButton}
                  icon={<CheckRoundNotFilledIcon />}
                  onClick={() =>
                    handleUpdateCompatibilityCell(currentExpandableCell.columnCode, true)
                  }
                >
                  Применять
                </Button>
                <Button
                  className={styles.rejectButton}
                  icon={<RestrictIcon />}
                  onClick={() =>
                    handleUpdateCompatibilityCell(currentExpandableCell.columnCode, false)
                  }
                >
                  Не применять
                </Button>
                <Button
                  type="link"
                  onClick={expandReset}
                >
                  Не уточнять
                </Button>
              </Flex>
            )}
          </Flex>
        ),
      [currentExpandableCell, expandReset, handleUpdateCompatibilityCell],
    );

    const tableExpandable: TableProps['expandable'] = useMemo(
      () => ({
        expandedRowKeys,
        expandedRowRender,
        showExpandColumn: false,
      }),
      [expandedRowKeys, expandedRowRender],
    );

    const rowClassName: TableProps<NmckCompatibilityRow>['rowClassName'] = useCallback(
      (record: NmckCompatibilityRow) =>
        expandedRowKeys.includes(record.id) ? styles.expandedRow : '',
      [expandedRowKeys],
    );

    useEffect(() => {
      expandReset();
    }, [rows, header, expandReset]);

    return (
      <ConfigProvider theme={theme}>
        <Table
          className={cx(styles.table, {
            [styles.tableWithVerticalScroll]: withVerticalScroll,
          })}
          rootClassName={styles.row}
          rowKey="id"
          pagination={false}
          scroll={scroll}
          columns={columns}
          dataSource={rows}
          loading={isLoading}
          expandable={tableExpandable}
          rowClassName={rowClassName}
        />
      </ConfigProvider>
    );
  },
);

CompatibilityTable.displayName = 'CompatibilityTable';
