import { memo, useCallback } from 'react';
import { Button, Flex, Space } from 'antd';
import Plus from '/public/icons/plus.svg';
import { useGetNmckById } from '@/api/nmckApi/nmckApi';
import { formatNumber } from '@/utils/formatNumber';
import styles from './NmckGridFooter.module.scss';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { ExportFile } from '@/components/ExportFile/ExportFile';
import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';

type Props = {
  nmckId: NmckByIdResponse['id'];
};

export const NmckGridFooter = memo(({ nmckId }: Props) => {
  const { createNmckRowWithFallback, downloadNmckExcelWithFallback } = useNmckFallbackDataHandler();
  const { data } = useGetNmckById(nmckId);

  const addRowButtonClick = useCallback(async () => {
    sendEventGTM(eventsGTM.nmckAddRow);
    await createNmckRowWithFallback(nmckId);
  }, [createNmckRowWithFallback, nmckId]);

  const generateReportButtonClick = useCallback(() => {
    sendEventGTM(eventsGTM.nmckDownloadExcel);
    data && downloadNmckExcelWithFallback(data.id, data.name);
  }, [data, downloadNmckExcelWithFallback]);

  return (
    <div className={styles.wrapper}>
      <Button
        icon={<Plus />}
        className="button-with-icon"
        onClick={addRowButtonClick}
      >
        Добавить позицию {data && data.rows.length + 1}
      </Button>
      <Flex
        align="center"
        gap={12}
      >
        <Flex align="center">
          <Space>
            <div className={styles.text}>Итого:</div>
            <div>{data && formatNumber(data.nmckTotal)} ₽</div>
          </Space>
        </Flex>
        <ExportFile onExportButtonClick={generateReportButtonClick} />
      </Flex>
    </div>
  );
});

NmckGridFooter.displayName = 'NmckGridFooter';
