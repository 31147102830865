import { memo, useCallback, useMemo } from 'react';
import { KtruDetail, OkpdDetail } from '@/api/okpdApi/okpdApiTypes';
import { Button, Flex, Typography } from 'antd';
import { StandardContract } from '@/widgets/OkpdKtru/components';
import { Badge } from '@/components';
import CheckRoundIcon from '/public/icons/checkRound.svg';
import CopyIcon from '/public/icons/copy.svg';
import SearchIcon from '/public/icons/search.svg';
import ListPaperIcon from '/public/icons/listPaper.svg';
import QuestionRoundIcon from '/public/icons/questionRound.svg';
import Link from 'next/link';
import cx from 'classnames';
import styles from './OkpdKtruDetailInfo.module.scss';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';
import { pageRoutes } from '@/app/pageRoutes';
import { OkpdLink } from '@/components/OkpdFlowModal/OkpdLink/OkpdLink';
import RestrictIcon from '/public/icons/restrict.svg';
import { NmckType } from '@/api/nmckApi/nmckApiTypes';
import { isKtruDetail } from '@/components/OkpdKtruDetail/utils';
import { getFormattedNkmi } from '@/widgets/OkpdKtru/utils/utils';
import { OkpdKtruXlsxButton } from '@/components/OkpdKtruDetail/OkpdKtruDetailInfo/components/OkpdKtruXlsxButton/OkpdKtruXlsxButton';

type OkpdDetailInfoProps = {
  data: OkpdDetail | KtruDetail;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
  showStandardContracts?: boolean;
};

const copyable = {
  tooltips: ['Нажмите чтобы скопировать', 'Скопировано'],
  icon: [
    <CopyIcon
      key="copy-icon"
      className={styles.copyIcon}
    />,
  ],
};

const StatusIcon = ({ active }: { active: boolean }) =>
  active ? (
    <CheckRoundIcon className={styles.statusIcon} />
  ) : (
    <RestrictIcon className={styles.statusIcon} />
  );

export const OkpdKtruDetailInfo = memo(
  ({ data, setModalPageParams, showStandardContracts = true }: OkpdDetailInfoProps) => {
    const isKtru = isKtruDetail(data);
    const title = `${data.code} — ${data.name}`;

    const recommendedCalculationMethodUrl = useMemo(() => {
      switch (data.nmckMethod.type) {
        case NmckType.MARKET: {
          const query = new URLSearchParams({
            ...(isKtru ? { ktruCode: data.code } : { okpdCode: data.code }),
            name: data.name,
          });

          return `${pageRoutes.nmckAnalysis}?${query}`;
        }
        case NmckType.DRUGS: {
          return pageRoutes.nmckDrugs;
        }
        case NmckType.GUARD: {
          return pageRoutes.nmckGuard;
        }
        case NmckType.MED_DEVICES: {
          const query = new URLSearchParams({
            ...(isKtru ? { ktruCode: data.code } : { okpdCode: data.code }),
            ...(isKtru && data.nkmis.length ? { nkmi: String(getFormattedNkmi(data.nkmis)) } : {}),
            name: data.name,
          });

          return `${pageRoutes.nmckMedDevices}?${query}`;
        }

        default: {
          return '';
        }
      }
    }, [data, isKtru]);

    const handleContractsClick = useCallback(() => {
      const query = new URLSearchParams({
        code: data.code,
      });

      window.open(`${pageRoutes.docs}?${query}`);
    }, [data.code]);

    const handleChildLinkClick = useCallback(
      (code: string) => () =>
        setModalPageParams &&
        setModalPageParams((prev) => ({
          ...prev,
          code,
        })),
      [setModalPageParams],
    );

    return (
      <Flex
        gap={64}
        className={styles.wrapper}
      >
        <Flex
          gap={8}
          vertical
          className={styles.left}
        >
          <div>
            <StatusIcon active={data.active} />
            <Typography.Text copyable={{ ...copyable, text: data.name }}>
              <span className={styles.title}>{title}</span>
            </Typography.Text>
          </div>
          {'children' in data && (
            <Flex
              vertical
              gap={12}
              className={styles.childrenList}
            >
              {data.children.map(({ id, code, name }) => (
                <OkpdLink
                  key={id}
                  isLink={!setModalPageParams}
                  href={`${pageRoutes.okpd2}/${code}`}
                  onClick={handleChildLinkClick(code)}
                  className={styles.childLink}
                >
                  {code} — {name}
                </OkpdLink>
              ))}
            </Flex>
          )}
        </Flex>

        <Flex
          vertical
          gap={8}
          className={styles.right}
        >
          <OkpdKtruXlsxButton
            isKtru={isKtru}
            code={data.code}
            title={title}
          />
          <div className={cx(styles.contract, styles.row)}>
            <QuestionRoundIcon />
            <div>
              Рекомендуемый метод расчёта НМЦК:{' '}
              <Link href={recommendedCalculationMethodUrl}>{data.nmckMethod.name}</Link>
            </div>
          </div>
          {showStandardContracts && (
            <div className={cx(styles.contract, styles.row)}>
              <ListPaperIcon />
              <StandardContract standardContract={data.standardContracts} />
            </div>
          )}
          <Flex
            align={'center'}
            gap={8}
          >
            <Button
              icon={<SearchIcon />}
              type="link"
              className={styles.button}
              onClick={handleContractsClick}
              disabled={!data.countOfContracts}
            >
              Связанные закупки
            </Button>
            <Badge
              count={data.countOfContracts}
              maxCount={1000}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  },
);

OkpdKtruDetailInfo.displayName = 'OkpdDetailInfo';
