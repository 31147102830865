import { memo, useCallback, useState } from 'react';
import { NmckByIdResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import styles from './NmckGridAmountInput.module.scss';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';
import { DebounceInputNumber, DebounceInputNumberValueType } from '@/components';

type AmountInputProps = {
  record: NmckRow;
  nmckId: NmckByIdResponse['id'];
};

export const NmckGridAmountInput = memo(({ record, nmckId }: AmountInputProps) => {
  const { updateNmckRowWithFallback } = useNmckFallbackDataHandler();
  const [amount, setAmount] = useState(record.amount);

  const handleAmountChange = useCallback(
    async (value: DebounceInputNumberValueType) => {
      setAmount(value ?? 0);
      await updateNmckRowWithFallback(nmckId, { ...record, amount: value ?? 0 });
    },
    [nmckId, record, updateNmckRowWithFallback],
  );

  return (
    <DebounceInputNumber
      min={0}
      value={amount}
      handleValueChange={handleAmountChange}
      className={styles.amount}
    />
  );
});

NmckGridAmountInput.displayName = 'NmckGridAmountInput';
