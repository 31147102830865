import { Dispatch, memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Flex, Table, TableProps } from 'antd';
import styles from './OkpdKtruSuppliersTable.module.scss';
import { OkpdKtruRequestKP } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruRequestKP/OkpdKtruRequestKP';
import {
  KtruDetail,
  OkpdDetail,
  SupplierResult,
  SuppliersResponse,
} from '@/api/okpdApi/okpdApiTypes';
import { TableFooter } from '@/components/Table/TableFooter/TableFooter';
import { PageSizeChangeCallbackType } from '@/components/Table/TablePagination/types';
import { useGetSuppliers } from '@/api/okpdApi/okpdApi';
import { SuppliersFilter } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/OkpdKtruSuppliersFilter';
import { useTokenBasedDataList } from '@/hooks';
import { LoadMoreCallbackType } from '@/components/Table/TablePagination/TableTokenBasedPagination/types';
import {
  TokenBasedPaginationStateWithPageSize,
  TokenBasedPaginationTokenValue,
} from '@/api/commonApi/commonApiTypes';
import cx from 'classnames';
import {
  tableColumns,
  tableScroll,
} from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersTable/constants';

type OkpdKtruSuppliersTable = {
  pagination: TokenBasedPaginationStateWithPageSize;
  filter: SuppliersFilter;
  setPagination: Dispatch<SetStateAction<TokenBasedPaginationStateWithPageSize>>;
  okpdKtruDetailData: OkpdDetail | KtruDetail;
};

export const OkpdKtruSuppliersTable = memo(
  ({ pagination, setPagination, filter, okpdKtruDetailData }: OkpdKtruSuppliersTable) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<SupplierResult['name'][]>([]);

    const { list, isLoading, token, hasMoreResults } = useTokenBasedDataList<
      SupplierResult,
      SuppliersResponse
    >({
      response: useGetSuppliers(filter, pagination),
      resetDeps: [filter, pagination.pageSize],
    });

    const selectedEmails = useMemo(
      () => selectedRowKeys.map((name) => list.find((item) => item.name === name)?.email ?? ''),
      [selectedRowKeys, list],
    );

    const rowSelection: TableProps['rowSelection'] = {
      columnWidth: '64px',
      selectedRowKeys,
      onChange: (newSelectedRowKeys) =>
        setSelectedRowKeys(newSelectedRowKeys as SupplierResult['name'][]),
    };

    const onLoadMore: LoadMoreCallbackType = useCallback(
      (token: TokenBasedPaginationTokenValue) => {
        setPagination((prev) => ({
          ...prev,
          token,
        }));
      },
      [setPagination],
    );

    const onPageSizeChange: PageSizeChangeCallbackType = useCallback(
      (pageSize: number) => {
        setPagination((prev) => ({
          ...prev,
          pageSize,
          token: null,
        }));
      },
      [setPagination],
    );

    const tokenBasedPaginationProps = useMemo(
      () => ({
        pagination: { token, hasMoreResults },
        pageSize: pagination.pageSize,
        isDataLoading: isLoading,
        onPageSizeChange,
        onLoadMore,
      }),
      [hasMoreResults, isLoading, onLoadMore, onPageSizeChange, pagination.pageSize, token],
    );

    const onRow: TableProps['onRow'] = useCallback(
      ({ name }: SupplierResult) => ({
        onClick: () => {
          if (selectedRowKeys.includes(name)) {
            setSelectedRowKeys((prev) => prev.filter((key) => key !== name));
          } else {
            setSelectedRowKeys((prev) => [...prev, name]);
          }
        },
      }),
      [selectedRowKeys],
    );

    const footer: TableProps['footer'] = useCallback(
      () => (
        <Flex
          align="center"
          justify="space-between"
        >
          <OkpdKtruRequestKP
            receivers={selectedEmails}
            okpdKtruDetailData={okpdKtruDetailData}
          />
          <TableFooter tokenBasedPagination={tokenBasedPaginationProps} />
        </Flex>
      ),
      [okpdKtruDetailData, selectedEmails, tokenBasedPaginationProps],
    );

    return (
      <Table
        columns={tableColumns}
        dataSource={list}
        loading={isLoading}
        scroll={tableScroll}
        className={cx(styles.table, 'table-with-sticky-footer')}
        rowSelection={rowSelection}
        onRow={onRow}
        rowKey="name"
        pagination={false}
        footer={footer}
      />
    );
  },
);

OkpdKtruSuppliersTable.displayName = 'OkpdKtruSuppliersTable';
