import { Dispatch, Key, memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Button, Flex } from 'antd';
import Cross from '/public/icons/cross.svg';
import PlusIcon from '/public/icons/plus.svg';
import ImportIcon from '/public/icons/import.svg';
import { PurchaseObjectTable } from '@/widgets/PlanningCurrent/components/PurchaseObjectTab/components/PurсhaseObjectTable/PurсhaseObjectTable';
import { TableColumnsSettings } from '@/components/Table/TableColumnsSettings/TableColumnsSettings';
import { useSortableTableColumns } from '@/components/Table/TableColumnsSettings/hooks';
import { resultsTableColumns } from './constants';
import { PurchaseItem } from '@/api/planningCurrentApi/planningCurrentApiTypes';
import styles from './PurchaseObjectTab.module.scss';
import { useParams } from 'next/navigation';
import {
  createCurrentPurchaseItem,
  deleteCurrentPurchaseItem,
  getCurrentPurchaseXlsx,
  useGetCurrentPurchaseById,
  useGetCurrentPurchaseCompatibility,
} from '@/api/planningCurrentApi/planningCurrentApi';
import { OkpdFlowModal } from '@/components/OkpdFlowModal/OkpdFlowModal';
import Warning from '/public/icons/warning.svg';
import { PlanningCurrentTabsType } from '@/widgets/PlanningCurrent/types';
import { ExportFile } from '@/components/ExportFile/ExportFile';
import { OkpdKtruModalPageParams } from '@/components/OkpdFlowModal/types';

type Props = {
  setActiveTab: Dispatch<SetStateAction<PlanningCurrentTabsType>>;
};

export const PurchaseObjectTab = memo(({ setActiveTab }: Props) => {
  const params = useParams();
  const purchaseId = Number(params.id);
  const [isOkpdFlowModalOpen, setIsOkpdFlowModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const {
    data,
    isLoading: isPurchaseItemsLoading,
    mutate: refreshPurchaseById,
  } = useGetCurrentPurchaseById(purchaseId);
  const { data: compatibilityData } = useGetCurrentPurchaseCompatibility(purchaseId);

  const compatibilityResult = useMemo(
    () => !!compatibilityData?.result,
    [compatibilityData?.result],
  );
  const purchaseItems = useMemo(() => data?.purchaseItems ?? [], [data]);

  const { orderedColumns, orderedColumnsMap, setOrderedColumnsMap } =
    useSortableTableColumns<PurchaseItem>({
      columns: resultsTableColumns,
    });

  const showAddNewModal = useCallback(() => {
    setIsOkpdFlowModalOpen(true);
  }, []);

  const handleDeletePurchaseItems = useCallback(async () => {
    await deleteCurrentPurchaseItem(selectedRowKeys, params.id);
    refreshPurchaseById();
  }, [params.id, refreshPurchaseById, selectedRowKeys]);

  const downloadReport = useCallback(() => {
    getCurrentPurchaseXlsx(params.id);
  }, [params.id]);

  const handleMoreButtonClick = useCallback(() => {
    setActiveTab(PlanningCurrentTabsType.COMPATIBILITY);
  }, [setActiveTab]);

  const handleSplitButtonClick = useCallback(() => {
    // TODO: Ручка для сплита не готова
  }, []);

  const handleAddToCalculationFromModal = useCallback(
    async (pageParams: OkpdKtruModalPageParams) => {
      await createCurrentPurchaseItem(
        {
          name: '',
          okpdCode: pageParams.code,
          chars: [],
          amount: 0,
          unit: '',
        },
        purchaseId,
      );
      await refreshPurchaseById();
    },
    [purchaseId, refreshPurchaseById],
  );

  return (
    <div>
      <OkpdFlowModal
        isOpen={isOkpdFlowModalOpen}
        setIsOpen={setIsOkpdFlowModalOpen}
        searchString=""
        onAddToCalculation={handleAddToCalculationFromModal}
      />
      {compatibilityResult && (
        <Flex
          className={styles.warning}
          justify="center"
          align="center"
        >
          <div className={styles.warningText}>
            <Warning />
            <span>Несовместимые коды ОКПД2/КТРУ.</span>
          </div>
          <div
            className={styles.more}
            onClick={handleMoreButtonClick}
          >
            Подробнее
          </div>
          <Button
            type="link"
            className={styles.split}
            onClick={handleSplitButtonClick}
          >
            Разделить закупку
          </Button>
          <Cross className={styles.cross} />
        </Flex>
      )}
      <PurchaseObjectTable
        selectedColumns={orderedColumns}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        purchaseItems={purchaseItems}
        isPurchaseItemsLoading={isPurchaseItemsLoading}
        refreshPurchaseById={refreshPurchaseById}
      />
      <div className={styles.settings}>
        <Flex
          gap={30}
          align="center"
        >
          <Button
            icon={<Cross />}
            className={styles.deleteBtn}
            disabled={selectedRowKeys.length === 0}
            onClick={handleDeletePurchaseItems}
          >
            Удалить
          </Button>
          <Button
            icon={<PlusIcon />}
            className={styles.addBtn}
            onClick={showAddNewModal}
          >
            Добавить объект
          </Button>
          <Button
            type="link"
            icon={<ImportIcon />}
            className={styles.importBtn}
          >
            Добавить из файла
          </Button>
          <TableColumnsSettings<PurchaseItem>
            orderedColumnMap={orderedColumnsMap}
            setOrderedColumnMap={setOrderedColumnsMap}
            columnsData={orderedColumns}
          />
        </Flex>
        <ExportFile
          onExportButtonClick={downloadReport}
          flexGap={24}
        />
      </div>
    </div>
  );
});
PurchaseObjectTab.displayName = 'PurchaseObjectTab';
