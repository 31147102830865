import { memo, useCallback, useState } from 'react';
import { Button } from 'antd';
import ExportIcon from '/public/icons/export.svg';
import styles from '@/components/OkpdKtruDetail/OkpdKtruDetailInfo/OkpdKtruDetailInfo.module.scss';
import { getKtruXlsx, getOkpdXlsx } from '@/api/okpdApi/okpdApi';
import cx from 'classnames';

type Props = {
  isKtru: boolean;
  code: string;
  title: string;
};

export const OkpdKtruXlsxButton = memo(({ isKtru, code, title }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleExcelDownload = useCallback(async () => {
    setIsLoading(true);
    try {
      if (isKtru) {
        await getKtruXlsx(code, title);
      } else {
        await getOkpdXlsx(code, title);
      }
    } catch (err: unknown) {
      console.error('Ошибка при загрузке xlsx', err);
    } finally {
      setIsLoading(false);
    }
  }, [code, isKtru, title]);

  return (
    <Button
      icon={<ExportIcon />}
      type="link"
      className={cx('button-with-icon', styles.button)}
      loading={isLoading}
      onClick={handleExcelDownload}
    >
      Excel
    </Button>
  );
});

OkpdKtruXlsxButton.displayName = 'OkpdKtruXlsxButton';
