import useSWR from 'swr';
import { deleteData, downloadXlsx, fetcher, getData, postData } from '@/api/commonApi/commonApi';
import { CharacteristicForFilter, Tru, TruCountResponse } from '@/api/okpdApi/okpdApiTypes';
import {
  AllNpa,
  KtruDetail,
  KtruResponse,
  OkpdDetail,
  OkpdInitialList,
  OkpdResponse,
  SuppliersResponse,
} from './okpdApiTypes';
import { generateOkpdKtruQueryParams, generateUrl } from '@/widgets/OkpdKtru/utils/utils';
import { TokenBasedPagination } from '@/types/types';
import { formatDate } from '@/utils';
import { OkpdKtruListFilter } from '@/widgets/OkpdKtru/types/types';
import { SuppliersFilter } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/OkpdKtruSuppliersFilter';
import { TokenBasedPaginationStateWithPageSize } from '@/api/commonApi/commonApiTypes';
import { NmckCompatibilityByIdResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { CompatibilityUpdateBody } from '@/api/planningCurrentApi/planningCurrentApiTypes';

export const useGetOkpdInitialList = () => {
  return useSWR('/okpd-list', getData<OkpdInitialList>, {
    revalidateOnFocus: false,
  });
};

export const useGetAllNpa = () => {
  return useSWR('/all-npa', getData<AllNpa>, {
    revalidateOnFocus: false,
  });
};

export const useGetKtruCharacteristics = ({
  okpdCode,
  searchString,
}: {
  okpdCode?: string;
  searchString?: string;
}) => {
  return useSWR(
    () =>
      okpdCode || searchString
        ? generateUrl('/ktru-characteristics', {
            okpdCode,
            searchstring: searchString,
          })
        : null,
    getData<CharacteristicForFilter[]>,
    {
      revalidateOnFocus: false,
    },
  );
};
export const useGetSuppliers = (
  filter: SuppliersFilter,
  pagination: TokenBasedPaginationStateWithPageSize,
) => {
  const params = new URLSearchParams();

  if (pagination.token) params.append('token', pagination.token);
  params.append('pageSize', String(pagination.pageSize));
  if (filter.okpdCode) params.append('okpdCode', filter.okpdCode);
  if (filter.ktruCode) params.append('ktruCode', filter.ktruCode);
  params.append('smp', String(filter.smp));
  params.append('prom', String(filter.prom));
  params.append('hasPenalty', String(filter.hasPenalty));
  if (filter.contractDateFrom)
    params.append('contractDateFrom', formatDate(filter.contractDateFrom, 'YYYY-MM-YY'));
  if (filter.contractDateTo)
    params.append('contractDateTo', formatDate(filter.contractDateTo, 'YYYY-MM-YY'));
  filter.region.forEach((item) => {
    params.append('regions', String(item));
  });

  return useSWR(`/suppliers?${params}`, getData<SuppliersResponse>, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
};

export const useGetOkpdList = (filter: OkpdKtruListFilter, pagination?: TokenBasedPagination) => {
  return useSWR(
    `/okpd?${generateOkpdKtruQueryParams({ pagination, filter })}`,
    getData<OkpdResponse>,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  );
};

export const useGetKtruList = (filter: OkpdKtruListFilter, pagination: TokenBasedPagination) => {
  return useSWR(
    `/ktru?${generateOkpdKtruQueryParams({ pagination, filter })}`,
    getData<KtruResponse>,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  );
};

export const useGetOkpdDetail = (code: string) =>
  useSWR(() => `/okpd/${code}`, getData<OkpdDetail>, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

export const useGetKtruDetail = (code: string) =>
  useSWR(() => `/ktru/${code}`, getData<KtruDetail>, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

export const useGetTruCount = () =>
  useSWR(() => '/tru-list/count', getData<TruCountResponse>, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

export const useGetTruList = ({ searchString }: { searchString?: string }) => {
  const params = new URLSearchParams();
  searchString && params.append('searchstring', searchString);

  const url = searchString ? `/nmck-tru?${params}` : null;

  return useSWR(url, getData<Tru[]>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  });
};

export const useGetTruCompatibility = (
  omitEmptyColumns: boolean = false,
  nationalProject: boolean = false,
) => {
  const params = new URLSearchParams();
  params.append('omitEmptyColumns', omitEmptyColumns.toString());
  params.append('nationalProject', nationalProject.toString());

  return useSWR(`/tru-list?${params}`, getData<NmckCompatibilityByIdResponse>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  });
};

export const postOkpdKtruToTRUList = (
  body: Partial<{
    okpdCode: string;
    ktruCode: string;
    nkmiId: NmckRow['nkmiId'];
  }>,
) => postData('/tru-list', body);

export const createCalculationFromTru = (nationalProject: boolean) => {
  const params = new URLSearchParams();
  params.append('nationalProject', nationalProject.toString());

  return postData<number[]>(`/tru-list/nmck?${params}`);
};

export const updateTruCompatibilityCell = (
  _: unknown,
  rowId: number,
  body: CompatibilityUpdateBody,
) => postData<void>(`/tru-list/${rowId}/compatibility-user-selection`, body);

export const getTruCompatibilityXlsx = async (
  omitEmptyColumns: boolean,
  nationalProject: boolean,
) => {
  const params = new URLSearchParams();
  params.append('omitEmptyColumns', omitEmptyColumns.toString());
  params.append('nationalProject', nationalProject.toString());

  const response = await fetcher(`/tru-list/xlsx?${params}`);
  await downloadXlsx(response, 'Список позиций ОКПД2/КТРУ');
};

export const deleteTruCompatibilityList = () => deleteData('/tru-list');

export const getOkpdXlsx = async (code: string, fileName: string) => {
  const response = await fetcher(`/okpd/${code}/xlsx`);
  await downloadXlsx(response, fileName);
};

export const getKtruXlsx = async (code: string, fileName: string) => {
  const response = await fetcher(`/ktru/${code}/xlsx`);
  await downloadXlsx(response, fileName);
};
