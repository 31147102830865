import { memo, PropsWithChildren, useCallback } from 'react';
import Link, { LinkProps } from 'next/link';
import { usePathname, useRouter } from 'next/navigation';

type Props = {
  className?: string;
} & LinkProps &
  PropsWithChildren;

export const NavigationLink = memo(({ children, href: linkHref, onClick, ...props }: Props) => {
  const pathname = usePathname();
  const router = useRouter();
  const href = String(linkHref);

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (pathname.startsWith(href)) {
        e.preventDefault();
        router.push(href);
        router.refresh();
        onClick?.(e);
      }
    },
    [href, onClick, pathname, router],
  );

  return (
    <Link
      {...props}
      href={href}
      onClick={handleLinkClick}
    >
      {children}
    </Link>
  );
});

NavigationLink.displayName = 'NavigationLink';
