import { pageRoutes, PageRouteValuesType } from '@/app/pageRoutes';
import { MenuItem, MenuItemInMap } from './type';
import { createMenuMap } from '@/components/Navigation/utils';

export const headerMenuList: MenuItem[] = [
  {
    title: 'ОКПД 2 / КТРУ',
    href: pageRoutes.okpd2,
  },
  {
    title: 'НМЦК',
    href: pageRoutes.nmck,
    children: [
      {
        title: 'История расчётов',
        href: pageRoutes.nmckHistory,
      },
      {
        title: 'Анализ рынка (567)',
        href: pageRoutes.nmckAnalysis,
      },
      {
        title: 'Лекарства (1064н)',
        href: pageRoutes.nmckDrugs,
      },
      {
        title: 'Медицинские изделия (450н)',
        href: pageRoutes.nmckMedDevices,
      },
      {
        title: 'Охрана (раздел II приказа 45)',
        href: pageRoutes.nmckGuard,
      },
    ],
  },
  {
    title: 'Расчёт сроков',
    href: pageRoutes.skp,
  },
  {
    title: 'Поиск документации',
    href: pageRoutes.docs,
  },
  // Временно скрываем (SARK-613)
  // {
  //   title: 'Планирование закупки',
  //   href: pageRoutes.planning,
  //   children: [
  //     {
  //       title: 'Все заявки',
  //       href: pageRoutes.planningAll,
  //     },
  //     {
  //       title: 'Текущая заявка',
  //       href: pageRoutes.planningCurrent,
  //     },
  //   ],
  // },
  {
    title: 'Текущие закупки',
    href: pageRoutes.current,
  },
  {
    title: 'Реестр закупок',
    href: pageRoutes.reestr,
    children: [
      // Временно скрываем (SARK-629)
      // {
      //   title: 'Все закупки из ЕИС',
      //   href: pageRoutes.reestrAll,
      // },
      {
        title: 'Прямые договоры',
        href: pageRoutes.reestrDirect,
      },
      // {
      //   title: 'Отчет СМП по 44-ФЗ',
      //   href: pageRoutes.reestrSmpReport,
      // },
      // {
      //   title: 'Сводка',
      //   href: pageRoutes.reestrSummary,
      // },
      // {
      //   title: 'Калькулятор пеней',
      //   href: pageRoutes.reestrPenaltiesCalc,
      // },
    ],
  },
];

export const headerMenuMap: Record<PageRouteValuesType, MenuItemInMap> =
  createMenuMap(headerMenuList);

export const layoutHeaderId = 'layout-header';
