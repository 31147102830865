'use client';

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import VIcon from '/public/icons/v.svg';
import { headerMenuList, layoutHeaderId } from './constants';
import { MenuItem, MouseActionMap } from './type';
import styles from './Navigation.module.scss';
import cx from 'classnames';
import { Flex } from 'antd';
import { PageRouteValuesType } from '@/app/pageRoutes';
import { isArray } from 'lodash';
import { VendorType } from '@/widgets/Header/types';
import { NavigationLink } from '@/components/Navigation/NavigationLink';

type Props = {
  className?: string;
  vendor: VendorType;
};

const menuItemsWithChildren = headerMenuList.filter(({ children }) => children);
const menuItemsWithChildrenMap: Record<PageRouteValuesType, MenuItem['children']> =
  menuItemsWithChildren.reduce(
    (acc, item) => ({
      ...acc,
      [item.href]: item.children,
    }),
    {} as Record<PageRouteValuesType, MenuItem['children']>,
  );

const LINK_ENTER_TIMEOUT_MS = 500;
const LAYOUT_HEADER_LEAVE_TIMEOUT_MS = 1000;

export const Navigation = memo(({ className: externalClassName, vendor }: Props) => {
  const url = usePathname();
  const lastLinkOverTimestamp = useRef<number | null>(null);
  const timeoutId = useRef<NodeJS.Timeout>();
  const headerLeaveTimeoutId = useRef<NodeJS.Timeout>();
  const [hoveredMenuItem, setHoveredMenuItem] = useState<PageRouteValuesType | null>(null);

  const currentRoute = useMemo(
    () => url.split('/').slice(0, 2).join('/') as PageRouteValuesType | null,
    [url],
  );

  const navigationClassNameByVendor = useMemo(
    () =>
      ({
        [VendorType.GPB]: styles.navigationGPB,
        [VendorType.ROS]: styles.navigationROS,
        [VendorType.PIRSON]: styles.navigationPIRSON,
      })[vendor],
    [vendor],
  );

  const activeMenuChildren = useMemo(() => {
    if (hoveredMenuItem) {
      return menuItemsWithChildrenMap[hoveredMenuItem];
    }
    if (currentRoute) {
      return menuItemsWithChildrenMap[currentRoute];
    }
  }, [currentRoute, hoveredMenuItem]);

  const menuItemToMouseOverHandlerMap: MouseActionMap = useMemo(
    () =>
      headerMenuList.reduce(
        (acc, item) => ({
          ...acc,
          [item.href]: () => {
            lastLinkOverTimestamp.current = Date.now();

            timeoutId.current = setTimeout(() => {
              setHoveredMenuItem(item.href);
            }, LINK_ENTER_TIMEOUT_MS);
          },
        }),
        {} as MouseActionMap,
      ),
    [],
  );

  const handleLinkMouseLeave = useCallback(() => {
    // очищаем последний таймаут, если пользователь не задержал курсор на ссылке
    // нужно в случае, если пользователь раскрыл дочерние ссылки и на миг завел курсор на другую родительскую ссылку
    if (
      lastLinkOverTimestamp.current &&
      Date.now() - lastLinkOverTimestamp.current < LINK_ENTER_TIMEOUT_MS
    ) {
      clearTimeout(timeoutId.current);
    }
  }, []);

  const getActiveLink = useCallback(
    (path: string) => {
      if (url.startsWith(path)) {
        return styles.active;
      }
      if (path === hoveredMenuItem && menuItemsWithChildrenMap[hoveredMenuItem]) {
        return styles.hovered;
      }

      return styles.notActive;
    },
    [hoveredMenuItem, url],
  );

  const handleHeaderMouseLeave = useCallback(() => {
    headerLeaveTimeoutId.current = setTimeout(
      () => setHoveredMenuItem(null),
      LAYOUT_HEADER_LEAVE_TIMEOUT_MS,
    );
  }, []);
  const handleHeaderMouseEnter = useCallback(() => {
    clearTimeout(headerLeaveTimeoutId.current);
  }, []);

  useEffect(() => {
    const header = document.getElementById(layoutHeaderId);
    if (header) {
      header.addEventListener('mouseleave', handleHeaderMouseLeave);
      header.addEventListener('mouseenter', handleHeaderMouseEnter);
    }

    return () => {
      if (header) {
        header.removeEventListener('mouseleave', handleHeaderMouseLeave);
        header.removeEventListener('mouseenter', handleHeaderMouseEnter);
      }
    };
  }, [handleHeaderMouseLeave, handleHeaderMouseEnter]);

  return (
    <nav className={cx(styles.navigation, navigationClassNameByVendor, externalClassName)}>
      <Flex
        align="center"
        className={styles.parentList}
        gap={32}
      >
        {headerMenuList.map((item) => (
          <Link
            key={item.href}
            href={item.href}
            className={cx(styles.link, getActiveLink(item.href))}
            onMouseOver={menuItemToMouseOverHandlerMap[item.href]}
            onMouseLeave={handleLinkMouseLeave}
          >
            {item.title}
            {item.children && <VIcon />}
          </Link>
        ))}
      </Flex>

      <div className={styles.childrenList}>
        {isArray(activeMenuChildren) && (
          <Flex
            align="center"
            gap={32}
          >
            {activeMenuChildren.map((item) => (
              <NavigationLink
                href={item.href}
                key={item.href}
                className={cx(styles.link, getActiveLink(item.href))}
              >
                {item.title}
              </NavigationLink>
            ))}
          </Flex>
        )}
      </div>
    </nav>
  );
});

Navigation.displayName = 'Navigation';
