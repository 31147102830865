import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CalculationMethod,
  NmckByIdResponse,
  NmckRow,
  VariationColor,
} from '@/api/nmckApi/nmckApiTypes';
import styles from './useNmckGrid.module.scss';
import { useGetNmckById } from '@/api/nmckApi/nmckApi';
import { TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { NameInput } from '@/components/NmckGrid/NameInput/NameInput';
import { Okpd2Input } from '@/components/NmckGrid/Okpd2Input/Okpd2Input';
import { NmckUnitMeasureSelect } from '@/components/NmckGrid/NmckUnitMeasureSelect/NmckUnitMeasureSelect';
import { NmckGridAmountInput } from '@/components/NmckGrid/NmckGridAmountInput/NmckGridAmountInput';
import { More } from '@/components/NmckGrid/More/More';
import { ColumnProps } from 'antd/es/table';
import { formatPrice } from '@/utils/formatPrice';
import { NmckGridNkmiAutocomplete } from '@/components/NmckGrid/NmckGridNkmiAutocomplete/NmckGridNkmiAutocomplete';
import { NmckGridContracts } from '@/components/NmckGrid/NmckGridContracts/NmckGridContracts';
import { formatNumber } from '@/utils/formatNumber';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { NmckCalculationMethodDropdown } from '@/components/NmckGrid/NmckCalculationMethodDropdown/NmckCalculationMethodDropdown';
import { NmckGridNdsSelect } from '@/components/NmckGrid/NmckGridNdsSelect/NmckGridNdsSelect';
import { NmckGridPropositionColumnTitle } from '@/components/NmckGrid/NmckGridProposition/NmckGridPropositionColumnTitle/NmckGridPropositionColumnTitle';
import { NmckGridExpandableButton } from '@/components/NmckGrid/NmckGridExpandableButton/NmckGridExpandableButton';
import { NmckGridPropositionInput } from '@/components/NmckGrid/NmckGridProposition/NmckGridPropositionInput/NmckGridPropositionInput';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';
import { useNmckPathname } from '@/components/NmckGrid/hooks/useNmckPathname';

type Props = {
  nmckId: NmckByIdResponse['id'];
};

export const useNmckGrid = ({ nmckId }: Props) => {
  const { isNmckMedDevices } = useNmckPathname();

  const [calculationMethod, setCalculationMethod] = useState<CalculationMethod>(() =>
    isNmckMedDevices ? CalculationMethod.WEIGHED_AVG : CalculationMethod.AVG,
  );
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const { createPropositionWithFallback, deletePropositionWithFallback } =
    useNmckFallbackDataHandler();
  const { data, isLoading } = useGetNmckById(nmckId);

  const list: NmckRow[] = useMemo(() => data?.rows ?? [], [data]);

  const rowClassName: TableProps['rowClassName'] = useCallback(
    (_: unknown, index: number) => {
      return expandedRowKeys.includes(index + 1) ? styles.rowExpanded : '';
    },
    [expandedRowKeys],
  );

  const handleAddProposition = useCallback(async () => {
    await createPropositionWithFallback(nmckId);
  }, [createPropositionWithFallback, nmckId]);

  const handleDeleteProposition = useCallback(
    async (columnNumber: number) => {
      await deletePropositionWithFallback(nmckId, columnNumber);
    },
    [deletePropositionWithFallback, nmckId],
  );

  const handleExpandRow = useCallback(
    (record: NmckRow) => {
      const newExpandedRowKeys = [...expandedRowKeys];
      const index = newExpandedRowKeys.indexOf(record.id);
      if (index > -1) {
        newExpandedRowKeys.splice(index, 1);
      } else {
        newExpandedRowKeys.push(record.id);
      }
      setExpandedRowKeys(newExpandedRowKeys);
    },
    [expandedRowKeys],
  );

  const handleEisSearchClick = useCallback(
    (record: NmckRow) => {
      sendEventGTM(eventsGTM.nmckEisOpen);
      handleExpandRow(record);
    },
    [handleExpandRow],
  );

  const expandable = useMemo(
    () => ({
      expandedRowKeys,
      expandedRowRender: (record: NmckRow) => <NmckGridContracts record={record} />,
      expandIconColumnIndex: -1,
    }),
    [expandedRowKeys],
  );

  const columns: ColumnsType<NmckRow> = useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'count',
        key: 'count',
        align: 'center',
        width: 35,
        render: (_: unknown, record: NmckRow, index: number) => {
          const formattedIndex = String(index + 1).padStart(2, '0');
          return <p className={styles.countIndex}>{formattedIndex}</p>;
        },
      },
      {
        title: 'Наименование',
        dataIndex: 'name',
        key: 'name',
        className: styles.colName,
        render: (_: unknown, record: NmckRow) => {
          return (
            <NameInput
              record={record}
              nmckId={nmckId}
            />
          );
        },
      },
      {
        title: 'ОКПД 2 / КТРУ',
        dataIndex: 'okpd2',
        key: 'okpd2',
        className: styles.colOkpdKtru,
        render: (_: unknown, record: NmckRow) => {
          return (
            <Okpd2Input
              row={record}
              nmckId={nmckId}
            />
          );
        },
      },
      ...(isNmckMedDevices
        ? [
            {
              title: 'НКМИ',
              dataIndex: 'nkmiId',
              key: 'nkmi',
              className: styles.colNkmi,
              render: (_: unknown, record: NmckRow) => (
                <NmckGridNkmiAutocomplete
                  nmckId={nmckId}
                  row={record}
                />
              ),
            },
          ]
        : []),
      ...(list[0]?.propositions?.map(
        (proposition, i, propositions): ColumnProps<NmckRow> => ({
          key: `price-${proposition.columnNumber}`,
          title: (
            <NmckGridPropositionColumnTitle
              columnIndex={i}
              propositions={propositions}
              onDeleteProposition={handleDeleteProposition}
              onAddProposition={handleAddProposition}
            />
          ),
          className: styles.colPrice,
          render: (_: unknown, record: NmckRow) => {
            return (
              <NmckGridPropositionInput
                columnIndex={i}
                row={record}
                nmckId={nmckId}
              />
            );
          },
        }),
      ) ?? []),
      {
        title: <div className={styles.variationHead}>Вариация</div>,
        dataIndex: 'variation',
        key: 'variation',
        align: 'end',
        render: (_: unknown, record: NmckRow) => {
          return (
            <div
              className={
                record.variationColor === VariationColor.GREEN
                  ? styles.variationGreen
                  : styles.variationRed
              }
            >
              {formatNumber(record.variation)}%
            </div>
          );
        },
      },
      {
        title: 'Ед. изм.',
        dataIndex: 'unit',
        key: 'unit',
        className: styles.colUnitMeasure,
        render: (_: unknown, record: NmckRow) => {
          return (
            <NmckUnitMeasureSelect
              record={record}
              nmckId={nmckId}
            />
          );
        },
      },
      {
        title: 'Кол-во',
        dataIndex: 'quantity',
        key: 'quantity',
        className: styles.colAmount,
        render: (_: unknown, record: NmckRow) => {
          return (
            <NmckGridAmountInput
              record={record}
              nmckId={nmckId}
            />
          );
        },
      },
      {
        title: (
          <NmckCalculationMethodDropdown
            nmckId={nmckId}
            calculationMethod={calculationMethod}
            setCalculationMethod={setCalculationMethod}
          />
        ),
        dataIndex: 'averagePrice',
        key: 'averagePrice',
        ellipsis: false,
        render: (_: unknown, record: NmckRow) => {
          return (
            <div className={styles.averagePrice}>
              {`${formatPrice(
                calculationMethod === CalculationMethod.MIN ? record.minPrice : record.averagePrice,
              )} ₽`}
            </div>
          );
        },
      },
      ...(isNmckMedDevices
        ? [
            {
              title: 'НДС',
              dataIndex: 'nds',
              key: 'nds',
              render: (_: unknown, record: NmckRow) => (
                <NmckGridNdsSelect
                  nmckId={nmckId}
                  row={record}
                />
              ),
            },
          ]
        : []),
      {
        title: 'НМЦК',
        dataIndex: 'nmck',
        key: 'nmck',
        align: 'end',
        render: (_: unknown, record: NmckRow) => (
          <div className={styles.nmckPrice}>{formatPrice(record.nmck)} ₽</div>
        ),
      },
      {
        title: '',
        dataIndex: 'more',
        key: 'more',
        render: (_: unknown, record: NmckRow) => (
          <More
            record={record}
            nmckId={nmckId}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'expandable-hidden-cell',
        key: 'expandable-hidden-cell',
        className: styles.hiddenCell,
        render: (_: unknown, record: NmckRow) => (
          <NmckGridExpandableButton
            row={record}
            onClick={handleEisSearchClick}
          />
        ),
      },
    ],
    [
      isNmckMedDevices,
      list,
      nmckId,
      calculationMethod,
      handleEisSearchClick,
      handleDeleteProposition,
      handleAddProposition,
    ],
  );

  useEffect(() => {
    if (data) {
      setCalculationMethod(data.calculationMethod);
    }
  }, [data]);

  return {
    columns,
    list,
    isLoading,
    rowClassName,
    expandable,
  };
};
