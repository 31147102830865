import {
  Dispatch,
  Key,
  memo,
  MouseEvent,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Button, Table, TableProps } from 'antd';
type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];
import { ColumnType } from 'antd/es/table';

import {
  CharacteristicType,
  PurchaseByIdResponse,
  PurchaseItem,
} from '@/api/planningCurrentApi/planningCurrentApiTypes';
import {
  DebounceInput,
  DebounceInputNumber,
  DebounceInputNumberValueType,
  OkpdFlowModal,
} from '@/components';
import { getRangeSign } from '../../utils';
import { PurchaseUnitMeasureSelect } from '@/widgets/PlanningCurrent/components/PurchaseObjectTab/components/PurchaseUnitMeasureSelect/PurchaseUnitMeasureSelect';
import styles from './PurсhaseObjectTable.module.scss';
import { omit } from 'lodash';
import { useParams } from 'next/navigation';
import {
  createCurrentPurchaseItem,
  updateCurrentPurchaseItem,
} from '@/api/planningCurrentApi/planningCurrentApi';
import { AddCharsModal } from '../AddCharsModal/AddCharsModal';
import { Columns } from '@/widgets/PlanningCurrent/components/PurchaseObjectTab/types';
import { OkpdKtruModalPageParams } from '@/components/OkpdFlowModal/types';
import { KeyedMutator } from 'swr';

const resultsTableColumnsWithRender = (
  selectedColumns: TableProps<PurchaseItem>['columns'],
  nameChange: (value: string, record: PurchaseItem) => void,
  amountChange: (value: DebounceInputNumberValueType, record: PurchaseItem) => void,
  okpdChange: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void,
  openAddCharsModal: (record: PurchaseItem) => void,
): TableProps<PurchaseItem>['columns'] => {
  return selectedColumns?.map((column: ColumnType<PurchaseItem>) => {
    switch (column.dataIndex) {
      case Columns.NAME:
        return {
          ...column,
          width: 50,
          render: (_: unknown, record: PurchaseItem) => (
            <DebounceInput
              value={record.name}
              handleValueChange={(value) => nameChange(value, record)}
              className={styles.name}
            />
          ),
        };
      case Columns.OKPD:
        return {
          ...column,
          width: 50,
          render: (_: unknown, record: PurchaseItem) => (
            <div
              className={styles.okpdCode}
              onClick={okpdChange}
            >
              {record.okpdCode}
            </div>
          ),
        };
      case Columns.CHARS:
        return {
          ...column,
          width: 456,
          render: (_: unknown, record: PurchaseItem) => (
            <div className={styles.chars}>
              {record.chars.length > 0 ? (
                <div>
                  {record.chars.map((char, index) => (
                    <div
                      key={index}
                      className={styles.charItem}
                    >
                      <div className={styles.charName}>{char.name}:</div>
                      {char.type === CharacteristicType.TEXT && (
                        <div className={styles.charValue}>{char.textValue}</div>
                      )}
                      {char.type === CharacteristicType.NUMERIC && (
                        <div className={styles.charValue}>{char.numValue}</div>
                      )}
                      {char.type === CharacteristicType.RANGE && (
                        <div className={styles.charValue}>
                          {char.leftRangeSign && getRangeSign(char.leftRangeSign)}
                          {' ' + char.leftRange}
                          {' и '}
                          {char.rightRangeSign && getRangeSign(char.rightRangeSign)}
                          {' ' + char.rightRange}
                        </div>
                      )}
                    </div>
                  ))}
                  <Button
                    className={styles.editChars}
                    onClick={() => openAddCharsModal(record)}
                  >
                    Указать
                  </Button>
                </div>
              ) : (
                <>
                  <Button
                    className={styles.addChars}
                    onClick={() => openAddCharsModal(record)}
                  >
                    Указать
                  </Button>
                </>
              )}
            </div>
          ),
        };
      case Columns.AMOUNT:
        return {
          ...column,
          width: 50,
          render: (_: unknown, record: PurchaseItem) => (
            <DebounceInputNumber
              placeholder="Не указано"
              value={record.amount}
              handleValueChange={(value) => amountChange(value, record)}
              className={styles.amount}
            />
          ),
        };
      case Columns.UNIT:
        return {
          ...column,
          render: (_: unknown, record: PurchaseItem) => (
            <PurchaseUnitMeasureSelect record={record} />
          ),
        };

      default:
        return column;
    }
  });
};

type PurshaseObjectTableProps = {
  selectedColumns: TableProps<PurchaseItem>['columns'];
  selectedRowKeys: Key[];
  setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>;
  purchaseItems?: PurchaseItem[];
  isPurchaseItemsLoading: boolean;
  refreshPurchaseById: KeyedMutator<PurchaseByIdResponse>;
};

export const PurchaseObjectTable = memo(
  ({
    selectedColumns,
    selectedRowKeys,
    setSelectedRowKeys,
    purchaseItems,
    isPurchaseItemsLoading,
    refreshPurchaseById,
  }: PurshaseObjectTableProps) => {
    const [selectedRecord, setSelectedRecord] = useState<PurchaseItem | null>(null);
    const [isOkpdFlowModalOpen, setIsOkpdFlowModalOpen] = useState(false);
    const [isAddCharsModalOpen, setIsAddCharsModalOpen] = useState(false);
    const [currentOkpd, setCurrentOkpd] = useState('');
    const params = useParams();
    const purchaseId = Number(params.id);

    const okpdChange = useCallback(async (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      const target = e.target as HTMLDivElement;
      setCurrentOkpd(target.innerText);
      setIsOkpdFlowModalOpen(true);
    }, []);

    const nameChange = useCallback(
      async (value: string, record: PurchaseItem) => {
        const updateBody = omit(record, ['id']);
        await updateCurrentPurchaseItem(params.id, record.id, {
          ...updateBody,
          name: value,
        });
      },
      [params.id],
    );
    const amountChange = useCallback(
      async (value: DebounceInputNumberValueType, record: PurchaseItem) => {
        const updateBody = omit(record, ['id']);
        await updateCurrentPurchaseItem(params.id, record.id, {
          ...updateBody,
          amount: value ?? 0,
        });
      },
      [params.id],
    );

    const openAddCharsModal = useCallback((record: PurchaseItem) => {
      setIsAddCharsModalOpen(true);
      setSelectedRecord(record);
    }, []);
    const closeAddCharsModal = useCallback(() => setIsAddCharsModalOpen(false), []);

    const resultsColumns = useMemo(() => {
      return resultsTableColumnsWithRender(
        selectedColumns,
        nameChange,
        amountChange,
        okpdChange,
        openAddCharsModal,
      ) as ColumnType<PurchaseItem>[];
    }, [selectedColumns, nameChange, amountChange, okpdChange, openAddCharsModal]);

    const rowSelection: TableRowSelection<PurchaseItem> = useMemo(() => {
      return {
        columnWidth: '64px',
        selectedRowKeys,
        onChange: setSelectedRowKeys,
      };
    }, [selectedRowKeys, setSelectedRowKeys]);

    const handleAddToCalculationFromModal = useCallback(
      async (pageParams: OkpdKtruModalPageParams) => {
        await createCurrentPurchaseItem(
          {
            name: '',
            okpdCode: pageParams.code,
            chars: [],
            amount: 0,
            unit: '',
          },
          purchaseId,
        );
        await refreshPurchaseById();
      },
      [purchaseId, refreshPurchaseById],
    );

    return (
      <div className={styles.wrapper}>
        {selectedRecord && (
          <AddCharsModal
            isOpenModal={isAddCharsModalOpen}
            closeModal={closeAddCharsModal}
            record={selectedRecord}
            refreshPurchaseById={refreshPurchaseById}
          />
        )}
        <OkpdFlowModal
          isOpen={isOkpdFlowModalOpen}
          setIsOpen={setIsOkpdFlowModalOpen}
          searchString={currentOkpd}
          onAddToCalculation={handleAddToCalculationFromModal}
        />
        <Table
          rowKey={'id'}
          rowClassName={styles.row}
          className={styles.table}
          loading={isPurchaseItemsLoading}
          dataSource={purchaseItems}
          columns={resultsColumns}
          pagination={false}
          rowSelection={rowSelection}
        />
      </div>
    );
  },
);

PurchaseObjectTable.displayName = 'PurchaseObjectTable';
