import { memo, useCallback, useState } from 'react';
import { Button } from 'antd';
import styles from './NmckGridExpandableButton.module.scss';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';
import VIcon from 'public/icons/v.svg';
import cx from 'classnames';

type Props = {
  row: NmckRow;
  onClick: (row: NmckRow) => void;
};

export const NmckGridExpandableButton = memo(({ row, onClick }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleButtonClick = useCallback(() => {
    setIsExpanded((prev) => !prev);
    onClick(row);
  }, [onClick, row]);

  return (
    <Button
      type="link"
      size="small"
      className={styles.button}
      onClick={handleButtonClick}
      iconPosition="end"
      icon={
        <VIcon
          className={cx(styles.vIcon, 'v-icon', {
            [styles.vIconExpanded]: isExpanded,
          })}
        />
      }
    >
      Поиск по контрактам ЕИС
    </Button>
  );
});

NmckGridExpandableButton.displayName = 'NmckGridExpandableButton';
