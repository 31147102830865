import {
  deleteNmckProposition,
  getNmckXlsx,
  postCopyNmckRow,
  postNewNmck,
  postNewNmckRow,
  postNewProposition,
  updateNmck,
  updateNmckRow,
  updateProposition,
  useGetNmckById,
} from '@/api/nmckApi/nmckApi';
import { omit } from 'lodash';
import { pageRoutes } from '@/app/pageRoutes';
import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { mutate } from 'swr';
import { useCallback, useContext } from 'react';
import { postNewNmckMedDevice } from '@/api/nmckMedDevicesApi/nmckMedDevicesApi';
import { useNmckPathname } from '@/components/NmckGrid/hooks/useNmckPathname';
import { NmckGridContext } from '@/components/NmckGrid/context/context';
import { actions } from '@/components/NmckGrid/context/actions';

export const useNmckFallbackDataHandler = () => {
  const { dispatch, state } = useContext(NmckGridContext);
  const { isNmckMedDevices } = useNmckPathname();

  const { nmckId } = state;

  const { mutate: refreshNmck } = useGetNmckById(nmckId);

  const createNmck = useCallback(async () => {
    if (isNmckMedDevices) {
      return postNewNmckMedDevice({
        copyFromId: null,
      });
    } else {
      return postNewNmck({
        copyFromId: null,
      });
    }
  }, [isNmckMedDevices]);

  const navigateToDetailPage = useCallback(
    (nmckId: NmckByIdResponse['id']) => {
      const path = isNmckMedDevices ? pageRoutes.nmckMedDevices : pageRoutes.nmckAnalysis;
      dispatch(actions.nmckIdUpdate(nmckId));
      window.history.pushState({}, '', `${path}/${nmckId}`);
    },
    [dispatch, isNmckMedDevices],
  );

  const updateNmckWithFallback = useCallback(
    async (...args: Parameters<typeof updateNmck>) => {
      if (!nmckId) {
        const nmck = await createNmck();
        const updatedNmck = await updateNmck({ ...nmck, ...omit(...args, ['id']) });
        await mutate(`/nmck/${nmck.id}`, updatedNmck, false);
        navigateToDetailPage(nmck.id);
      } else {
        await updateNmck(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck],
  );

  const updateNmckRowWithFallback = useCallback(
    async (...args: Parameters<typeof updateNmckRow>) => {
      if (!nmckId) {
        const { id } = await createNmck();
        const updatedNmck = await updateNmckRow(id, args[1]);
        await mutate(`/nmck/${id}`, updatedNmck, false);
        navigateToDetailPage(id);
      } else {
        await updateNmckRow(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck],
  );

  const updatePropositionWithFallback = useCallback(
    async (...args: Parameters<typeof updateProposition>) => {
      if (!nmckId) {
        const { id, rows } = await createNmck();
        const updatedNmck = await updateProposition(id, rows[0].id, args[2], args[3]);
        await mutate(`/nmck/${id}`, updatedNmck, false);
        navigateToDetailPage(id);
      } else {
        await updateProposition(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck],
  );

  const createPropositionWithFallback = useCallback(
    async (...args: Parameters<typeof postNewProposition>) => {
      if (!nmckId) {
        const { id } = await createNmck();
        const updatedNmck = await postNewProposition(id);
        await mutate(`/nmck/${id}`, updatedNmck, false);
        navigateToDetailPage(id);
      } else {
        await postNewProposition(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck],
  );

  const deletePropositionWithFallback = useCallback(
    async (...args: Parameters<typeof deleteNmckProposition>) => {
      if (!nmckId) {
        const { id } = await createNmck();
        const updatedNmck = await deleteNmckProposition(id, args[1]);
        await mutate(`/nmck/${id}`, updatedNmck, false);
        navigateToDetailPage(id);
      } else {
        await deleteNmckProposition(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck],
  );

  const createNmckRowWithFallback = useCallback(
    async (...args: Parameters<typeof postNewNmckRow>) => {
      if (!nmckId) {
        const { id } = await createNmck();
        const updatedNmck = await postNewNmckRow(id);
        await mutate(`/nmck/${id}`, updatedNmck, false);
        navigateToDetailPage(id);
      } else {
        await postNewNmckRow(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck],
  );

  const downloadNmckExcelWithFallback = useCallback(
    async (...args: Parameters<typeof getNmckXlsx>) => {
      if (!nmckId) {
        const nmck = await createNmck();
        await mutate(`/nmck/${nmck.id}`, nmck, false);
        await getNmckXlsx(nmck.id, args[1]);
        navigateToDetailPage(nmck.id);
      } else {
        await getNmckXlsx(...args);
      }
    },
    [createNmck, navigateToDetailPage, nmckId],
  );

  const createCopyNmck = useCallback(
    async (...args: Parameters<typeof postCopyNmckRow>) => {
      if (!nmckId) {
        const nmck = await createNmck();
        const updatedNmck = await postCopyNmckRow(nmck.id, args[1]);
        await mutate(`/nmck/${nmck.id}`, updatedNmck, false);
        navigateToDetailPage(nmck.id);
      } else {
        await postCopyNmckRow(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck],
  );

  return {
    updateNmckWithFallback,
    updateNmckRowWithFallback,
    updatePropositionWithFallback,
    createPropositionWithFallback,
    deletePropositionWithFallback,
    createNmckRowWithFallback,
    createCopyNmck,
    downloadNmckExcelWithFallback,
  };
};
