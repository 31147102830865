import { memo, useCallback, useEffect, useState } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { ndsOptions } from '@/components/NmckGrid/NmckGridNdsSelect/contstants';
import { NmckByIdResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import styles from './NmckGridNdsSelect.module.scss';
import VIcon from 'public/icons/v.svg';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';

type Props = {
  row: NmckRow;
  nmckId: NmckByIdResponse['id'];
};

export const NmckGridNdsSelect = memo(({ row, nmckId }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [nds, setNds] = useState(row.nds);
  const { updateNmckRowWithFallback } = useNmckFallbackDataHandler();

  const handleSelectChange: SelectProps['onChange'] = useCallback(
    async (value: number) => {
      setIsLoading(true);
      setNds(value);
      await updateNmckRowWithFallback(nmckId, { ...row, nds: value });
      setIsLoading(false);
    },
    [nmckId, row, updateNmckRowWithFallback],
  );

  useEffect(() => {
    setNds(row.nds);
  }, [row.nds]);

  return (
    <Select
      value={nds}
      className={styles.select}
      loading={isLoading}
      options={ndsOptions}
      suffixIcon={isLoading ? <Spin size="small" /> : <VIcon />}
      onChange={handleSelectChange}
    />
  );
});

NmckGridNdsSelect.displayName = 'NmckGridNdsSelect';
