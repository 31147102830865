import { usePathname } from 'next/navigation';
import { pageRoutes } from '@/app/pageRoutes';

export const useNmckPathname = () => {
  const pathname = usePathname();
  const isNmckMedDevices = pathname.startsWith(pageRoutes.nmckMedDevices);

  return {
    isNmckMedDevices,
  };
};
