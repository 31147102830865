import { Actions, ActionType } from './actions';
import { BDUIFilterParam } from '@/components/BDUIFilter/types';
import { PaginationV1 } from '@/components/Table/TablePagination/types';

export type ReestrDirectParams = {
  search: string;
  pagination: PaginationV1;
  filters: BDUIFilterParam[];
};

export const initialState: ReestrDirectParams = {
  search: '',
  pagination: {
    pageNumber: 1,
    pageSize: 10,
  },
  filters: [],
};

export const reestrDirectReducer = (
  state: ReestrDirectParams = initialState,
  action: Actions,
): ReestrDirectParams => {
  switch (action.type) {
    case ActionType.SET_SEARCH:
      return { ...state, search: action.payload };
    case ActionType.PAGINATION_UPDATE:
      return { ...state, pagination: action.payload };
    case ActionType.FILTERS_UPDATE:
      return { ...state, filters: action.payload };
    default:
      return state;
  }
};
