import { memo, useCallback, useMemo, useState } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { useGetUnitsMeasure } from '@/api/nmckApi/nmckApi';
import VIcon from 'public/icons/v.svg';
import { OptionWithNumberValue } from '@/types/types';
import { filterOption } from '@/utils/filterOptions';
import { NmckByIdResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';

type Props = {
  nmckId?: NmckByIdResponse['id'];
  rowId?: NmckRow['id'];
  fetchOptionsAfterFocus?: boolean;
  value?: OptionWithNumberValue;
  onChange: (value: OptionWithNumberValue) => void;
} & Omit<SelectProps, 'value' | 'options' | 'labelInValue' | 'onChange'>;

export const UnitMeasureSelect = memo(
  ({
    nmckId,
    rowId,
    fetchOptionsAfterFocus = false,
    value,
    placeholder = 'Выберите значение',
    onChange,
    ...selectProps
  }: Props) => {
    const [isFocused, setIsFocused] = useState<boolean>(!fetchOptionsAfterFocus);

    const { data = [], isLoading } = useGetUnitsMeasure({
      shouldFetch: isFocused,
      rowId,
      nmckId,
    });

    const options: OptionWithNumberValue[] = useMemo(
      () => data.map(({ name, unit }) => ({ label: name, value: unit })),
      [data],
    );

    const handleFocus: SelectProps['onFocus'] = useCallback(() => {
      if (fetchOptionsAfterFocus && !isFocused) {
        setIsFocused(true);
      }
    }, [fetchOptionsAfterFocus, isFocused]);

    return (
      <Select<OptionWithNumberValue, OptionWithNumberValue>
        value={value}
        labelInValue
        options={options}
        placeholder={placeholder}
        showSearch
        filterOption={filterOption}
        notFoundContent={isLoading ? <Spin size="small" /> : undefined}
        suffixIcon={isLoading ? <Spin size="small" /> : <VIcon className="v-icon" />}
        onFocus={handleFocus}
        onChange={onChange}
        {...selectProps}
      />
    );
  },
);

UnitMeasureSelect.displayName = 'UnitMeasureSelect';
