'use client';

import { memo, useCallback, useMemo } from 'react';
import { NmckByIdResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { OptionWithNumberValue } from '@/types/types';
import { UnitMeasureSelect } from '@/components/UnitMeasureSelect/UnitMeasureSelect';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';
import styles from './NmckUnitMeasureSelect.module.scss';

const dropdownStyle = {
  width: 240,
};

type UnitMeasureSelectProps = {
  record: NmckRow;
  nmckId: NmckByIdResponse['id'];
};

export const NmckUnitMeasureSelect = memo(({ record, nmckId }: UnitMeasureSelectProps) => {
  const { unit, unitCode } = record;
  const { updateNmckRowWithFallback } = useNmckFallbackDataHandler();

  const value = useMemo<OptionWithNumberValue | undefined>(
    () => (unit && unitCode ? { value: unitCode, label: unit } : undefined),
    [unit, unitCode],
  );

  const selectUnitMeasure = useCallback(
    async (option?: OptionWithNumberValue) => {
      await updateNmckRowWithFallback(nmckId, {
        ...record,
        unit: option?.label ?? '',
        unitCode: option?.value,
      });
    },
    [nmckId, record, updateNmckRowWithFallback],
  );

  return (
    <UnitMeasureSelect
      placeholder={'Ед. изм.'}
      rowId={record.id}
      nmckId={nmckId}
      fetchOptionsAfterFocus
      allowClear
      value={value}
      className={styles.units}
      dropdownStyle={dropdownStyle}
      onChange={selectUnitMeasure}
    />
  );
});
NmckUnitMeasureSelect.displayName = 'NmckUnitMeasureSelect';
