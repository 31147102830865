'use client';

import { Button, Flex } from 'antd';
import { useRouter } from 'next/navigation';

export default function Error({}: { error: Error & { digest?: string } }) {
  const router = useRouter();

  const handleBackButtonClick = () => {
    router.back();
  };

  return (
    <Flex
      align="center"
      justify="center"
      vertical
      gap={24}
    >
      <h2>Что-то пошло не так</h2>
      <Button onClick={handleBackButtonClick}>Назад</Button>
    </Flex>
  );
}
