import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { AutoComplete, Flex, InputRef, Spin, Tooltip } from 'antd';
import DotsIcon from '/public/icons/dots.svg';
import { NmckByIdResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import styles from './Okpd2Input.module.scss';
import { KtruResult, OkpdResult, Tru } from '@/api/okpdApi/okpdApiTypes';
import { useGetTruList } from '@/api/okpdApi/okpdApi';
import { DebounceInput, OkpdFlowModal } from '@/components';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { OkpdKtruModalPageParams, OkpdKtruPageTypes } from '@/components/OkpdFlowModal/types';
import WarningNotFilledIcon from 'public/icons/warningNotFilled.svg';
import { warningTooltipOverlayInnerStyles } from '@/components/NmckGrid/Okpd2Input/constants';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';

export const renderOption = ({ code, name }: OkpdResult | KtruResult | Tru) => (
  <Flex align="center">
    <div>{`${code} -`}&nbsp;</div>
    <Tooltip
      arrow={false}
      title={<div dangerouslySetInnerHTML={{ __html: name }} />}
      placement="right"
    >
      <div
        className={styles.optionName}
        dangerouslySetInnerHTML={{ __html: name }}
      />
    </Tooltip>
  </Flex>
);

type Props = {
  row: NmckRow;
  nmckId: NmckByIdResponse['id'];
};

export const Okpd2Input = memo(({ row, nmckId }: Props) => {
  const { updateNmckRowWithFallback } = useNmckFallbackDataHandler();
  const [searchString, setSearchString] = useState(row.code ?? '');
  const [isOkpdFlowModalOpen, setIsOkpdFlowModalOpen] = useState(false);
  const inputRef = useRef<InputRef>(null);

  const { data, isLoading } = useGetTruList({
    searchString: searchString || row.name,
  });

  const handleUpdateRow = useCallback(
    async (updatedRow: NmckRow) => {
      await updateNmckRowWithFallback(nmckId, updatedRow);
    },
    [nmckId, updateNmckRowWithFallback],
  );

  const handleOkpdInputChange = useCallback(
    async (value: string) => {
      setSearchString(value);
      await handleUpdateRow({ ...row, code: value });
    },
    [handleUpdateRow, row],
  );

  const options = useMemo(
    () =>
      data?.map((item: Tru) => ({
        value: item.code,
        data: item,
        label: renderOption(item),
      })) ?? [],
    [data],
  );

  const onSelect = useCallback(
    async (_: unknown, { data: { code, nkmiId } }: { data: Tru }) => {
      sendEventGTM(eventsGTM.nmckRowOkpdChangeBySelect, { value: code });
      await handleUpdateRow({ ...row, code, nkmiId });
      inputRef.current?.blur();
      setSearchString(code);
    },
    [handleUpdateRow, row],
  );

  const handleOpenModal = useCallback(() => setIsOkpdFlowModalOpen(true), []);

  const handleAddToCalculationFromModal = useCallback(
    async ({ page, code }: OkpdKtruModalPageParams) => {
      const isKtru = page === OkpdKtruPageTypes.KTRU;
      setSearchString(code);
      sendEventGTM(
        isKtru ? eventsGTM.nmckRowKtruChangeByModal : eventsGTM.nmckRowOkpdChangeByModal,
        {
          value: code,
        },
      );

      await handleUpdateRow({
        ...row,
        ...{ code },
      });
    },
    [handleUpdateRow, row],
  );

  const inputSuffix = useMemo(
    () => (
      <Flex
        gap={10}
        align="center"
      >
        {row.codeError && (
          <Tooltip
            title={row.codeError}
            overlayInnerStyle={warningTooltipOverlayInnerStyles}
          >
            <WarningNotFilledIcon />
          </Tooltip>
        )}
        {isLoading ? (
          <Spin size="small" />
        ) : (
          <DotsIcon
            className={styles.dotsIcon}
            onClick={handleOpenModal}
          />
        )}
      </Flex>
    ),
    [handleOpenModal, isLoading, row.codeError],
  );

  return (
    <>
      <AutoComplete
        options={options}
        popupMatchSelectWidth={500}
        onSelect={onSelect}
        value={searchString}
        className={styles.autocomplete}
      >
        <DebounceInput
          debounceMs={800}
          ref={inputRef}
          className={styles.input}
          status={row.codeError ? 'warning' : ''}
          suffix={inputSuffix}
          handleValueChange={handleOkpdInputChange}
        />
      </AutoComplete>

      <OkpdFlowModal
        isOpen={isOkpdFlowModalOpen}
        setIsOpen={setIsOkpdFlowModalOpen}
        searchString={searchString}
        onAddToCalculation={handleAddToCalculationFromModal}
      />
    </>
  );
});

Okpd2Input.displayName = 'Okpd2Input';
