import { BDUIFilterParam } from '@/components/BDUIFilter/types';
import { PaginationV1 } from '@/components/Table/TablePagination/types';

export enum ActionType {
  SET_SEARCH = 'SET_SEARCH',
  PAGINATION_UPDATE = 'PAGINATION_UPDATE',
  FILTERS_UPDATE = 'FILTERS_UPDATE',
}

type ActionCreator<T extends ActionType, P> = (payload: P) => {
  type: T;
  payload: P;
};

const actionCreator = <P, T extends ActionType>(type: T) =>
  ((payload: P) => ({
    type,
    payload,
  })) as ActionCreator<T, P>;

export const actions = {
  setSearch: actionCreator<string, ActionType.SET_SEARCH>(ActionType.SET_SEARCH),
  paginationUpdate: actionCreator<PaginationV1, ActionType.PAGINATION_UPDATE>(
    ActionType.PAGINATION_UPDATE,
  ),
  filtersUpdate: actionCreator<BDUIFilterParam[], ActionType.FILTERS_UPDATE>(
    ActionType.FILTERS_UPDATE,
  ),
};

export type Actions = ReturnType<(typeof actions)[keyof typeof actions]>;
