'use client';

import { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { DocsTable, Header, CharacteristicsModal } from '@/widgets/Docs/components';
import { useGetContractDocuments } from '@/api/docsApi/docsApi';
import { usePagination } from '@/widgets/Docs/utils/usePagination';
import { SearchContext } from '@/widgets/Docs/context/context';
import { actions } from '@/widgets/Docs/context/actions';
import styles from './DocsPage.module.scss';

export const DocsPage = memo(() => {
  const { state, dispatch } = useContext(SearchContext);

  const { items, pages, getPageInfo, getLastInfo, reset, savePaginationInfo } = usePagination(
    state.itemsPerPage,
  );

  const {
    data,
    isLoading: isContractDocumentsLoading,
    isValidating: isContractDocumentsValidating,
  } = useGetContractDocuments(state);

  const contractDocuments = useMemo(() => (state.code ? (data ?? []) : []), [data, state.code]);

  const pageChange = useCallback(
    (page: number) => {
      const { eisId, rank } = getPageInfo(page);

      dispatch(actions.setCurrentPage(page));
      dispatch(actions.setEis(eisId));
      dispatch(actions.setRank(rank));
    },
    [dispatch, getPageInfo],
  );

  const loadMore = useCallback(() => {
    const lastInfo = getLastInfo();

    if (!lastInfo) return;
    dispatch(actions.setCurrentPage(pages + 1));
    dispatch(actions.setEis(lastInfo.eisId));
    dispatch(actions.setRank(lastInfo.rank));
  }, [dispatch, getLastInfo, pages]);

  const closeModal = useCallback(() => {
    dispatch(actions.showModal(false));
  }, [dispatch]);

  useEffect(() => {
    if (!data || isContractDocumentsValidating || !contractDocuments.length) return;
    savePaginationInfo(contractDocuments.at(-1)?.eisId, contractDocuments.at(-1)?.rank);
  }, [contractDocuments, data, isContractDocumentsValidating, savePaginationInfo]);
  return (
    <div className={styles.wrapper}>
      <Header reset={reset} />
      <DocsTable
        data={contractDocuments}
        loading={isContractDocumentsLoading}
        items={items}
        pageChange={pageChange}
        loadMore={loadMore}
      />
      {state.characteristics && (
        <CharacteristicsModal
          open={state.modal}
          items={state.characteristics}
          onCancel={closeModal}
        />
      )}
    </div>
  );
});

DocsPage.displayName = 'DocsPage';
