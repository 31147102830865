import { NmckByIdResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { memo, useCallback, useState } from 'react';
import styles from './NameInput.module.scss';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';
import { DebounceInput } from '@/components';

type NameInputProps = {
  record: NmckRow;
  nmckId: NmckByIdResponse['id'];
};

export const NameInput = memo(({ record, nmckId }: NameInputProps) => {
  const { updateNmckRowWithFallback } = useNmckFallbackDataHandler();
  const [name, setName] = useState(record.name);

  const handleChange = useCallback(
    async (value: string) => {
      await updateNmckRowWithFallback(nmckId, { ...record, name: value });
      sendEventGTM(eventsGTM.nmckRowNameChange, { value });
      setName(value);
    },

    [nmckId, record, updateNmckRowWithFallback],
  );
  return (
    <DebounceInput
      className={styles.nameInput}
      value={name}
      handleValueChange={handleChange}
    />
  );
});
NameInput.displayName = 'NameInput';
