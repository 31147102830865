import { Dispatch, memo, MouseEvent, SetStateAction, useCallback, useState } from 'react';
import styles from './CalculationHistoryCard.module.scss';
import { Button, Flex, Popconfirm, Spin, Tooltip } from 'antd';
import cx from 'classnames';
import { formatDate } from '@/utils';
import { Tag } from '@/components';
import { formatNumber } from '@/utils/formatNumber';
import Copy from 'public/icons/copy.svg';
import Export from 'public/icons/export.svg';
import Repeat from '/public/icons/new.svg';
import Delete from '/public/icons/trash.svg';
import { NmckType } from '@/api/nmckApi/nmckApiTypes';
import { preventEventPropagation } from '@/utils/preventEventPropagation';
import { useRouter } from 'next/navigation';
import { nmckData } from '@/widgets/NmckHistory/constants';
import {
  getNmckDrugXlsx,
  postNewNmckDrugs,
  postRepeatedDrugCalculation,
} from '@/api/nmckDrugsApi/nmckDrugsApi';
import { deleteNmck, getNmckXlsx, postNewNmck } from '@/api/nmckApi/nmckApi';
import { applyCardElementTemporaryHighlight } from '@/widgets/NmckHistory/utils';
import { NmckDrugsByIdResponse } from '@/api/nmckDrugsApi/nmckDrugsApiTypes';
import {
  failedCreateRecurrencyNotification,
  successCreateRecurrencyNotification,
} from '@/widgets/NmckDrugs/components/DrugsGridHeader/utils';
import { NmckCommon } from '@/widgets/NmckHistory/types';

type Props = {
  nmck: NmckCommon;
  setNmckList: Dispatch<SetStateAction<NmckCommon[]>>;
};

export const CalculationHistoryCard = memo(({ nmck, setNmckList }: Props) => {
  const router = useRouter();
  const [copyLoading, setCopyLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [recurrencyLodaing, setRecurrencyLodaing] = useState(false);

  const handleCopyButtonClick = useCallback(
    async (e: MouseEvent) => {
      e.stopPropagation();
      setCopyLoading(true);
      let newNmck: NmckCommon;

      if (nmck.type === NmckType.DRUGS) {
        newNmck = await postNewNmckDrugs({ copyFromId: nmck.id });
      } else {
        newNmck = await postNewNmck({ copyFromId: nmck.id });
      }

      setNmckList((prevData) => [newNmck, ...prevData]);
      applyCardElementTemporaryHighlight(newNmck.id);
      setCopyLoading(false);
    },
    [nmck.id, nmck.type, setNmckList],
  );

  const handleDrugRepeatedCalculationButtonClick = useCallback(
    async (event: MouseEvent) => {
      setRecurrencyLodaing(true);
      event.stopPropagation();

      try {
        const newNmck: NmckDrugsByIdResponse = await postRepeatedDrugCalculation(nmck.id);
        successCreateRecurrencyNotification(newNmck.recurrencyBadge);
        setNmckList((prevData) => [newNmck, ...prevData]);
        applyCardElementTemporaryHighlight(newNmck.id);
      } catch (e) {
        failedCreateRecurrencyNotification();
      }
      setRecurrencyLodaing(false);
    },
    [nmck.id, setNmckList],
  );

  const handleExportButtonClick = useCallback(
    async (e: MouseEvent) => {
      setExportLoading(true);
      e.stopPropagation();
      if (nmck.type === NmckType.DRUGS) {
        await getNmckDrugXlsx(nmck.id, nmck.name);
      } else {
        await getNmckXlsx(nmck.id, nmck.name);
      }
      setExportLoading(false);
    },
    [nmck.id, nmck.name, nmck.type],
  );

  const handleDelete = useCallback(() => {
    const element = document.getElementById(`nmck-${nmck.id}`);
    if (element) {
      element.classList.add(styles.removed);
      setTimeout(() => {
        setNmckList((prevData) => prevData.filter((item) => item.id !== nmck.id));
        deleteNmck(nmck.id);
      }, 1000);
    }
  }, [nmck.id, setNmckList]);

  const handleCardClick = useCallback(() => {
    router.push(`${nmckData[nmck.type].route}/${nmck.id}`);
  }, [nmck.id, nmck.type, router]);

  return (
    <Flex
      align="center"
      gap={16}
      onClick={handleCardClick}
      className={styles.card}
      id={`nmck-${nmck.id}`}
      key={nmck.id}
    >
      <Flex gap={16}>
        <div className={cx(styles.cardDate, styles.columnDate)}>{formatDate(nmck.modified)}</div>
        <Tooltip
          placement="bottom"
          arrow={false}
          title={nmck.generatedName ? `${nmck.name} (${nmck.generatedName})` : `${nmck.name}`}
        >
          <div className={cx(styles.cardName, styles.columnName)}>
            {nmck.generatedName ? `${nmck.name} (${nmck.generatedName})` : `${nmck.name}`}
          </div>
        </Tooltip>
        <Flex
          className={cx(styles.cardMethod, styles.columnMethod)}
          align="center"
          gap={16}
        >
          <span>{nmckData[nmck.type].label}</span>
          {nmck.recurrencyBadge && <Tag label={nmck.recurrencyBadge} />}
        </Flex>
        <div className={cx(styles.total, styles.columnPrice)}>{formatNumber(nmck.nmckTotal)} ₽</div>
      </Flex>

      <Flex
        gap={16}
        justify="space-between"
        className={styles.cardActions}
      >
        <Flex
          gap={16}
          align="center"
        >
          <Button
            type="link"
            onClick={handleCopyButtonClick}
            icon={copyLoading ? <Spin size="small" /> : <Copy />}
          />
          <Button
            className="button-with-icon"
            type="link"
            onClick={handleExportButtonClick}
            icon={exportLoading ? <Spin size="small" /> : <Export />}
          >
            Excel
          </Button>
          {nmck.type === NmckType.DRUGS && (
            <Button
              type="link"
              icon={recurrencyLodaing ? <Spin size="small" /> : <Repeat />}
              className="button-with-icon"
              disabled={nmck.recurrency === 3}
              onClick={handleDrugRepeatedCalculationButtonClick}
            >
              Повторный расчёт по приказу 1064н п.12
            </Button>
          )}
        </Flex>
        <Popconfirm
          title="Удаление расчёта"
          description="Вы уверены что хотите удалить этот расчёт?"
          okText="Да"
          cancelText="Нет"
          onConfirm={handleDelete}
          onPopupClick={preventEventPropagation}
        >
          <Button
            icon={<Delete />}
            type="link"
            className={styles.cardDelete}
            onClick={preventEventPropagation}
          />
        </Popconfirm>
      </Flex>
    </Flex>
  );
});

CalculationHistoryCard.displayName = 'CalculationHistoryCard';
