import { Metadata } from 'next';
import { PageRouteValuesType } from '@/app/pageRoutes';
import { headerMenuMap } from '@/components/Navigation/constants';

type PageMetaDataParams = {
  staticTitle?: string;
  additionalTitle?: string;
  pageRoute?: PageRouteValuesType;
};

export const generatePageMetaData = ({
  pageRoute,
  staticTitle,
  additionalTitle,
}: PageMetaDataParams): Omit<Metadata, 'title'> & { title: string } => {
  return {
    title: generateTitle({ staticTitle, additionalTitle, pageRoute }),
  };
};

const generateTitle = ({
  staticTitle,
  additionalTitle,
  pageRoute,
}: Pick<PageMetaDataParams, 'staticTitle' | 'additionalTitle' | 'pageRoute'>) => {
  let title: string;

  if (staticTitle) {
    title = staticTitle;
  } else if (pageRoute) {
    title = headerMenuMap[pageRoute].title;
  } else {
    title = 'Pirson';
  }

  if (additionalTitle) {
    title += ` | ${additionalTitle}`;
  }

  return title;
};
