'use client';

import { memo, useContext, useEffect } from 'react';
import { NmckGridHeader } from '@/components/NmckGrid/NmckGridHeader/NmckGridHeader';
import styles from './NmckAnalysisPage.module.scss';
import { pageRoutes } from '@/app/pageRoutes';
import { NmckGridContext } from '@/components/NmckGrid/context/context';
import { NmckAnalysisTable } from '@/widgets/NmckAnalysis/components/NmckAnalysisTable';
import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { actions } from '@/components/NmckGrid/context/actions';

type Props = {
  nmckId: NmckByIdResponse['id'];
};

export const NmckAnalysisPageContent = memo(({ nmckId: nmckIdParam }: Props) => {
  const { dispatch, state } = useContext(NmckGridContext);
  const { nmckId } = state;

  useEffect(() => {
    nmckIdParam && dispatch(actions.nmckIdUpdate(nmckIdParam));
  }, [dispatch, nmckId, nmckIdParam]);

  return (
    <>
      <NmckGridHeader
        nmckId={nmckId || nmckIdParam}
        newCalculationLink={pageRoutes.nmckAnalysis}
      >
        <div className={styles.title}>Расчёт НМЦК</div>
      </NmckGridHeader>
      <NmckAnalysisTable nmckId={nmckId || nmckIdParam} />
    </>
  );
});

NmckAnalysisPageContent.displayName = 'NmckAnalysisPageContent';
